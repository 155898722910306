export const getAtendendoComo = (id) => {
  switch (id) {
    case 1:
      return 'Delivery'
    case 2:
      return 'Retirada'
    case 3:
      return 'Delivery e Retirada'
  }
}

export const getStatus = (id) => {
  switch (id) {
    case 1:
      return 'Cadastro rejeitado'
    case 2:
      return 'Não autorizado em decreto'
    case 3:
      return 'Contactado'
    case null:
      return '-'
    default:
      return '-'
  }
}
