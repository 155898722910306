import React, { useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

import BusinessIcon from '@material-ui/icons/Business'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import NotificationsIcon from '@material-ui/icons/Notifications'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'

import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/drawerContent'
import { ApplicationContext } from '../ContextProvider.jsx'
import * as usuarios from '../constants/usuarios'

function DrawerContent(props) {
  const [userMenu, setUserMenu] = useState(null)

  const { user } = useContext(ApplicationContext)

  const handleMenuClick = (event) => {
    setUserMenu(event.currentTarget)
  }

  const handleMenuClose = () => {
    setUserMenu(null)
  }

  const { classes } = props

  const path = props.match.path

  const drawerContent = (
    <div className={classes.list}>
      <div
        tabIndex={0}
        role='button'
        onClick={props.toggleDrawer ? props.toggleDrawer(false) : () => ''}
        onKeyDown={props.toggleDrawer ? props.toggleDrawer(false) : () => ''}
      >
        <List>
          <Link className={classes.reactRouterLink} to='/'>
            <ListItem
              button
              key='home'
              style={{ backgroundColor: path === '/' ? '#eee' : '' }}
              disabled={user.tipo === usuarios.JORNALISTA}
            >
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary='Capa' />
            </ListItem>
          </Link>

          <Link className={classes.reactRouterLink} to='/notificacoes'>
            <ListItem
              button
              key='home'
              style={{
                backgroundColor: path === '/notificacoes' ? '#eee' : '',
              }}
              disabled={user.tipo === usuarios.JORNALISTA}
            >
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary='Notificações' />
            </ListItem>
          </Link>

          <Link className={classes.reactRouterLink} to='/fotos-e-videos'>
            <ListItem
              button
              key='home'
              style={{
                backgroundColor: path === '/fotos-e-videos' ? '#eee' : '',
              }}
            >
              <ListItemIcon>
                <PhotoLibraryIcon />
              </ListItemIcon>
              <ListItemText primary='Fotos e Vídeos' />
            </ListItem>
          </Link>

          <Link className={classes.reactRouterLink} to='/estabelecimentos'>
            <ListItem
              button
              key='home'
              style={{
                backgroundColor: path === '/estabelecimentos' ? '#eee' : '',
              }}
              disabled={user.tipo === usuarios.CONTEUDO}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary='Estabelecimentos' />
            </ListItem>
          </Link>
        </List>
      </div>
    </div>
  )

  return (
    <>
      <div className={classes.logoRPCDiv}>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <img
            className={classes.logoRPC}
            src='/rpc-logo-fundo-escuro.png'
            alt='Logo RPC'
          />

          <Typography className={classes.logoTitle} component='h1' variant='h6'>
            Dashboard de Conteúdo
          </Typography>
        </Link>

        <Button
          aria-owns={userMenu ? 'userMenu' : undefined}
          aria-haspopup='true'
          onClick={handleMenuClick}
          className={classes.userButton}
        >
          <Grid item xs={12}>
            <p className={classes.userName}>{user.nome}</p>
          </Grid>
        </Button>
        <Menu
          id='userMenu'
          anchorEl={userMenu}
          open={Boolean(userMenu)}
          onClose={handleMenuClose}
          PaperProps={{
            className: classes.userMenuPaper,
          }}
        >
          <MenuItem onClick={handleMenuClose}>Sair</MenuItem>
        </Menu>
      </div>
      {drawerContent}
    </>
  )
}
export default withStyles(styles)(withRouter(DrawerContent))
