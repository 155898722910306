export const styles = (theme) => ({
  button: {
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: '#DEDEDE',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
  },

  buttonActive: {
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
  },

  chip: {
    margin: 8,
  },

  textField: {
    width: 'calc(100% - 30px)',
    marginLeft: 15,
    marginRight: 15,
  },

  sectionTitle: {
    fontSize: '1rem',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  uploadedImage: {
    width: '100%',
    height: 300,
    objectFit: 'cover',
    objectPosition: 'top',
  },

  removeUploadedImage: {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    height: 10,
    width: 35,
  },

  imageUploadDestaquesPreview: {
    objectFit: 'cover',
    position: 'relative',
    width: 200,
    height: 148,
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  imageUploadFixosPreview: {
    objectFit: 'cover',
    position: 'relative',
    width: 200,
    height: 118,
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  imagePreviewTitle: {
    marginBottom: 8,
    textAlign: 'center',
    fontWeight: 500,
  },

  estabelecimentoEndereco: {
    border: '1px solid #aaa',
    backgroundColor: '#eee',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
})
