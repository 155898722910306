// Capa
export const initialFeed = {
  id_categoria_envio: null,
  ids_pracas: [],
  pracas_comportamentos: [],
  requer_autenticacao: false,
  id_feed_conteudo_tipo: 2,
  id_categoria: 1,
  url: '',
  url_nome: '',
  titulo: '',
  imagem: '',
  imagem_upload: '',
  imagem_link: '',
  data_vigencia_inicio: new Date(),
  data_vigencia_fim: null,
}

export const initialDialogCapaChangeComportamentoState = {
  id_feed: null,
  ids_pracas: [],
  id_comportamento_feed: 1,
  data_vigencia_inicio: null,
  data_vigencia_fim: null,
}

// Notificações
export const initialDialogNotificacoesState = {
  feedId: null,
  ids_pracas: [],
  ids_interesses: [],
  id_push: null,
  nome_push: '',
  id_categoria: 1,
  titulo: '',
  mensagem: '',
  url: '',
  importante: false,
  data_hora: new Date(),
}

// Categorias
export const initialCategorias = [
  {
    id: 1,
    ids_pracas: [1, 2],
    nome: 'Dia dos Namorados',
    created_at: new Date(),
    pracas: [
      {
        id: 1,
        nome: 'Cascavel',
      },
      {
        id: 2,
        nome: 'Curitiba',
      },
    ],
  },
  {
    id: 2,
    ids_pracas: [2],
    nome: 'Dia dos Pais',
    created_at: new Date(),
    pracas: [
      {
        id: 2,
        nome: 'Curitiba',
      },
    ],
  },
  {
    id: 3,
    ids_pracas: [2],
    nome: 'Dia das Mães',
    created_at: new Date(),
    pracas: [
      {
        id: 2,
        nome: 'Curitiba',
      },
    ],
  },
  {
    id: 4,
    ids_pracas: [2],
    nome: 'Aniversário de Curitiba',
    created_at: new Date(),
    pracas: [
      {
        id: 2,
        nome: 'Curitiba',
      },
    ],
  },
  {
    id: 5,
    ids_pracas: [2],
    nome: 'Aniversário de Guarapuava',
    created_at: new Date(),
    pracas: [
      {
        id: 2,
        nome: 'Curitiba',
      },
    ],
  },
]

export const initialCategoriasColumns = [
  {
    name: 'Id',
    value: 'id',
    type: 'string',
  },
  {
    name: 'Nome',
    value: 'nome',
    type: 'string',
  },
]

// Estabelecimentos
export const initialDialogEstabelecimentosState = {
  id_categoria_estabelecimento: '',
  categoria_estabelecimento: {
    id: '',
    nome: '',
  },
  nome: '',
  o_que_vende: '',
  atendendo_como: '',
  nome_fantasia: '',
  cnpj: '',
  email: '',
  whatsapp: '',
  instagram: '',
  facebook: '',
  site: '',
  observacoes: '',
  ativo: '',
  negocio: '',
  enderecos: [],
}

// General
export const initialDialogDeleteState = {
  ids_pracas: [],
  selected_ids_pracas: [],
  id_feed: null,
}

export const initialDialogSearchState = {
  params: {
    id_envio: '',
    id_categoria_envio: '',
    ids_comportamentos: [],
    ids_categorias: [],
    telespectador: '',
    palavras_chave: [],
    data_inicio: null,
    data_fim: null,
    publicado: 'todos',
  },
  buscaSimples: false,
}

export const initialDialogEstabelecimentoSearchState = {
  params: {
    cidade: '',
    ativo: true,
    negocio: true,
    id_categoria_estabelecimento: '',
    nome: '',
    atendendo_como: '',
    palavras_chave: [],
    status: ''
  },
}

// =====================================================

export const initialCardsCapa = {
  feed_manual: [
    // {
    //   id: 1,
    //   id_categoria: 1, // G1, GShow...
    //   id_feed_conteudo_tipo: 2, // Notícia ou Interação
    //   titulo: 'Governo libera mais R$ 154 milhões em emendas parlamentares em meio à votação da Previdência',
    //   titulo_alt: '',
    //   imagem: 'https://s2.glbimg.com/Rd1ERbi7eMOa6Svyd2OLHGsZ1eU=/0x0:6720x4480/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2019/S/3/dBCsLBSBCPlYwsplpABg/plenario-camara.jpg',
    //   imagem_alt: null,
    //   url: 'https://g1.globo.com/politica/noticia/2019/07/12/governo-libera-mais-r-154-milhoes-em-emendas-parlamentares-em-meio-a-votacao-da-previdencia.ghtml',
    //   requer_autenticacao: false,
    //   data_publicacao: new Date(),
    //   url_hash: '',
    //   url_amp: '',
    //   resumo: 'Com liberações pelo terceiro dia consecutivo para a área de saúde, foi autorizado mais de R$ 1,7 bilhão em três dias.',
    //   categoria: {
    //     nome: 'G1',
    //   },
    //   feed_conteudo_tipo: {
    //     nome: 'Notícia',
    //   },
    //   comportamento_feed: {
    //     id: 1,
    //     descricao: 'Breaking',
    //   },
    // },
    {
      id: 2,
      id_categoria: 1, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo: 'Harley-Davidson planeja lançar moto elétrica no Brasil em 2020',
      titulo_alt: '',
      imagem:
        'https://s2.glbimg.com/2ZeE60eRzfudryuRnLcHwkevC7o=/0x0:5472x3648/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2019/9/d/XRlB5eQmKNungvgWp0dA/lrm-export-27134443296092-20190711-224322184.jpeg',
      imagem_alt: null,
      url:
        'https://g1.globo.com/carros/motos/noticia/2019/07/12/harley-davidson-planeja-lancar-moto-eletrica-no-brasil-em-2020.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'G1',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      comportamento_feed: {
        id: 2,
        descricao: 'Destaque',
      },
      feeds_praca: [
        {
          id: 11,
          id_praca: 2,
          id_feed: 2,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },

    {
      id: 3,
      id_categoria: 3, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo: "Rock faz promessa a Jô: 'Vou mostrar quem você é'",
      titulo_alt: '',
      imagem:
        'https://s2.glbimg.com/OWvRmn3HbuCxMjJN6GBvCb6FSVg=/0x0:946x541/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_e84042ef78cb4708aeebdf1c68c6cbd6/internal_photos/bs/2019/h/a/2FgBUhQyqCMQoeBkdDhQ/rock-caio-castro-a-dona-do-pedaco.png',
      imagem_alt: null,
      url:
        'https://gshow.globo.com/novelas/a-dona-do-pedaco/vem-por-ai/noticia/rock-faz-promessa-a-jo-vou-mostrar-quem-voce-e.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'GSHOW',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      comportamento_feed: {
        id: 3,
        descricao: 'Fixo',
      },
      feeds_praca: [
        {
          id: 12,
          id_praca: 2,
          id_feed: 3,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },
    {
      id: 4,
      id_categoria: 4, // G1, GShow...
      id_feed_conteudo_tipo: 1, // Notícia ou Interação
      titulo:
        'Cinema, teatro e muito rock marcam o Minuto Cultural deste fim de semana',
      titulo_alt: '',
      imagem:
        'https://s2.glbimg.com/QOljgaaCs65Ang2YvyNd5IdMu9A=/0x0:1392x783/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_b58693ed41d04a39826739159bf600a0/internal_photos/bs/2019/2/h/qA1V1TRNKFlt2EzDfAkw/vlcsnap-2019-07-11-18h51m46s410.png',
      imagem_alt: null,
      url:
        'https://redeglobo.globo.com/rpc/diversao-e-arte/Curitiba/noticia/cinema-teatro-e-muito-rock-marcam-o-minuto-cultural-deste-fim-de-semana.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'RPC',
      },
      feed_conteudo_tipo: {
        nome: 'Interação',
      },
      comportamento_feed: {
        id: 3,
        descricao: 'Fixo',
      },
      feeds_praca: [
        {
          id: 13,
          id_praca: 2,
          id_feed: 4,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },
    {
      id: 25,
      id_categoria: 2, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo:
        'Dinheiro compra títulos? Eis a lista dos clubes mais eficientes do futebol brasileiro em 2018',
      titulo_alt: '',
      imagem:
        'https://s2.glbimg.com/ZHEsvFhblfIKx78_NmHE5BhkoQM=/0x0:1080x608/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_bc8228b6673f488aa253bbcb03c80ec5/internal_photos/bs/2019/Q/F/ZOAKYAQfmU9PKIj5IOBg/carrossel-balanco-financeiro-serie-a.png',
      imagem_alt: null,
      url:
        'https://globoesporte.globo.com/blogs/blog-do-rodrigo-capelo/post/2019/07/12/dinheiro-compra-titulos-eis-a-lista-dos-clubes-mais-eficientes-do-futebol-brasileiro-em-2018.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'GE',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      comportamento_feed: {
        id: 3,
        descricao: 'Fixo',
      },
      feeds_praca: [
        {
          id: 14,
          id_praca: 2,
          id_feed: 25,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },

        {
          id: 15,
          id_praca: 1,
          id_feed: 25,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },
    {
      id: 16,
      id_categoria: 1, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo:
        'Após Previdência, Guedes prepara medidas econômicas e quer foco na reforma do Estado',
      titulo_alt: '',
      imagem:
        'https://s2.glbimg.com/ioPyv_f1-tRgw7shTbOfShlACSA=/0x0:1558x1354/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2019/c/Y/zDiVmXRpSNBgrfupIJ1Q/paulo-guedes-retrato.jpg',
      imagem_alt: null,
      url:
        'https://g1.globo.com/politica/blog/andreia-sadi/post/2019/07/12/apos-previdencia-guedes-prepara-medidas-economicas-e-quer-foco-na-reforma-do-estado.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'G1',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      comportamento_feed: {
        id: 3,
        descricao: 'Fixo',
      },
      feeds_praca: [
        {
          id: 15,
          id_praca: 2,
          id_feed: 16,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },
    {
      id: 29,
      id_categoria: 1, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo: 'Governo confirma rompimento de barragem em cidade na Bahia',
      titulo_alt: '',
      imagem:
        'https://s2.glbimg.com/0FDzTHEMnxSQ7veDLCL2D1k-ytA=/0x0:1280x853/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2019/G/F/PErZ2KRemLNHnyWvdHxw/whatsapp-image-2019-07-12-at-12.01.35-2-.jpeg',
      imagem_alt: null,
      url:
        'https://g1.globo.com/ba/bahia/noticia/2019/07/12/governo-confirma-rompimento-de-barragem-em-cidade-na-bahia.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'G1',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      comportamento_feed: {
        id: 2,
        descricao: 'Fixo',
      },
      feeds_praca: [
        {
          id: 17,
          id_praca: 2,
          id_feed: 29,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },
    {
      id: 18,
      id_categoria: 1, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo:
        'Estudantes bloqueiam Esplanada dos Ministérios em protesto contra reforma da Previdência',
      titulo_alt: '',
      imagem:
        'https://s2.glbimg.com/FK41v3j2StoljIhS5A9gfZdml2k=/0x0:1920x1080/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2019/p/O/6K5ASySmq6prOVhgVZXw/manifesta2.png',
      imagem_alt: null,
      url:
        'https://g1.globo.com/df/distrito-federal/noticia/2019/07/12/estudantes-bloqueiam-esplanada-dos-ministerios-em-protesto-contra-reforma-da-previdencia.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'G1',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      comportamento_feed: {
        id: 2,
        descricao: 'Fixo',
      },
      feeds_praca: [
        {
          id: 23,
          id_praca: 2,
          id_feed: 18,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },
  ],

  feed_automatico: [
    {
      id: 119,
      id_categoria: 1, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo: 'Alan Turing será homenageado na nota de 50 libras na Inglaterra',
      titulo_alt: '',
      imagem:
        'https://s2.glbimg.com/4RH2el4tXireqYthoKfdKapdgnk=/0x0:846x605/1600x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2019/G/V/BNDooLSeypKS813p2DqQ/turing.jpg',
      imagem_alt: null,
      url:
        'https://g1.globo.com/mundo/noticia/2019/07/15/alan-turing-sera-homenageado-na-nota-de-50-libras-na-inglaterra.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'G1',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      feeds_praca: [
        {
          id: 24,
          id_praca: 2,
          id_feed: 19,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },

    {
      id: 320,
      id_categoria: 1, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo:
        'Após quatro meses em queda, atividade econômica volta a subir em maio, indica BC',
      titulo_alt: '',
      imagem: null,
      imagem_alt: null,
      url:
        'https://g1.globo.com/economia/noticia/2019/07/15/apos-quatro-meses-em-queda-atividade-economica-volta-a-subir-em-maio-indica-bc.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'G1',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      feeds_praca: [
        {
          id: 25,
          id_praca: 2,
          id_feed: 320,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },

    {
      id: 211,
      id_categoria: 1, // G1, GShow...
      id_feed_conteudo_tipo: 2, // Notícia ou Interação
      titulo:
        'Camelô do Rio procura cliente que pagou balas de R$ 2 com nota de R$ 100',
      titulo_alt: '',
      imagem: null,
      imagem_alt: null,
      url:
        'https://g1.globo.com/rj/rio-de-janeiro/noticia/2019/07/15/camelo-do-rio-procura-cliente-que-pagou-balas-de-r-2-com-nota-de-r-100.ghtml',
      requer_autenticacao: false,
      data_publicacao: new Date(),
      url_hash: '',
      url_amp: '',
      resumo: '',
      categoria: {
        nome: 'G1',
      },
      feed_conteudo_tipo: {
        nome: 'Notícia',
      },
      feeds_praca: [
        {
          id: 13,
          id_praca: 2,
          id_feed: 211,
          audit_atualizado_por: '1',
          feed_praca_comportamento_feed: [
            {
              id: 10,
              id_comportamento_feed: 2,
              id_feed_praca: 10,
              ordem: 1,
              data_vigencia_inicio: '2019-07-20T21:00:00.000Z',
              data_vigencia_fim: '2019-07-20T20:00:00.000Z',
            },
          ],
        },
      ],
    },
  ],
}

export const initialNotificacoes = [
  {
    id: 1,
    id_categoria: 2, // G1
    id_status_agendamento_notificacao: 1,
    titulo: 'Copa do Brasil - Semifinal!',
    mensagem:
      'É DECISÃO: começa agora o duelo entre Flamengo e Athletico-PR valendo vaga na semifinal da Copa do Brasil',
    url:
      'https://globoesporte.globo.com/futebol/copa-do-brasil/noticia/flamengo-x-athletico-pr-tudo-o-que-voce-precisa-saber-sobre-o-jogo-de-volta-das-quartas-de-final.ghtml',
    importante: 0,
    data_hora: new Date(),
    categoria: {
      nome: 'GE',
    },
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: new Date(),
  },
  {
    id: 2,
    id_categoria: 4,
    id_status_agendamento_notificacao: 1,
    titulo: 'Oportunidade de emprego',
    mensagem:
      'Seleção para 250 vagas de emprego em rede de supermercado em Curitiba se encerra nesta quarta-feira (17)',
    url:
      'https://g1.globo.com/pr/parana/noticia/2019/07/17/selecao-para-250-vagas-de-emprego-em-rede-de-supermercado-em-curitiba-se-encerra-nesta-quarta-feira-17.ghtml',
    importante: 1,
    data_hora: new Date(),
    categoria: {
      nome: 'RPC',
    },
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: new Date(),
  },
  {
    id: 3,
    id_categoria: 1, // G1
    id_status_agendamento_notificacao: 1,
    titulo: 'O Inverno chegou',
    mensagem:
      'TEMPO EM CURITIBA NESTA QUARTA-FEIRA. Manhã vai ser ensolarada e nuvens chegam à tarde. Mínima de 4ºC e máxima de 16ºC. Veja os detalhes no G1 Paraná',
    url: 'https://g1.globo.com/previsao-do-tempo/pr/curitiba.ghtml',
    importante: 0,
    data_hora: new Date(),
    categoria: {
      nome: 'G1',
    },
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: new Date(),
  },
  {
    id: 4,
    id_categoria: 3,
    id_status_agendamento_notificacao: 1,
    titulo: 'Dalton Trevisan que se cuide!',
    mensagem:
      "Prepare o alho, a água benta e o crucifixo! Hoje os vampiros invadem o penúltimo episódio de 'Cine Holliúdy'",
    url:
      'https://gshow.globo.com/series/cine-holliudy/noticia/ney-latorraca-revive-conde-vlad-da-novela-vamp-em-cine-holliudy-vi-como-e-forte-o-personagem.ghtml',
    importante: 0,
    data_hora: new Date(),
    categoria: {
      nome: 'GSHOW',
    },
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: new Date(),
  },
]

export const initialNotificacoesColumns = [
  {
    name: 'Id',
    value: 'id',
    type: 'string',
  },
  {
    name: 'Título',
    value: 'titulo',
    type: 'string',
  },
  {
    name: 'Data de Envio',
    value: 'data_hora',
    type: 'date',
  },
]

// Fotos e Vídeos
export const initialFotosEVideos = [
  {
    id: 1,
    id_usuario_app: 12,
    id_categoria_envio: 1,
    titulo: 'Shaman',
    descricao:
      'O Dia Mundial do Rock foi comemorado no último sábado (13). E, com isso, o prefeito de São Paulo, Bruno Covas anunciou que dia 8 de junho será o Dia Municipal do Metal. O dia em questão marca a morte de André Matos, vocalista de grandes bandas como Angra, Shaman e Viper, e grande nome do gênero no Brasil.',
    status: 1,
    usuario: {
      id: 12,
      email: 'brenov@rpc.com.br',
      nome: 'Breno Velasco',
      telefone: '41999674105',
      firebase_uid: 0,
      id_praca: 1,
      id_frequencia_notificacao: 1,
    },
    categoria: {
      nome: 'Dia do Metal',
      ativo: 1,
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: null,
    },
    arquivos: [
      {
        id: 1,
        id_envio: 1,
        path:
          'http://1.bp.blogspot.com/-sXn9stwUywE/Vqu2AOOs7tI/AAAAAAAAM3w/qdvbo4Xc0LA/s1600/shamanvolta---.jpg',
        status: 1,
        tipo: 1,
        created_at: new Date(),
        updated_at: new Date(),
        deleted_at: null,
      },
      {
        id: 2,
        id_envio: 1,
        path:
          'https://cdn.domtotal.com/img/noticias/2019-06/1363191_407678.jpg',
        status: 1,
        tipo: 1,
        created_at: new Date(),
        updated_at: new Date(),
        deleted_at: null,
      },
    ],
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: null,
  },

  {
    id: 2,
    id_usuario_app: 10,
    id_categoria_envio: 2,
    titulo: 'Jardim Botânico - Lindo!',
    descricao:
      'No Jardim das Sensações dá pra ver as plantas com as mãos, sentir o chão com os pés, ouvir a voz do vento e da cascata, aspirar o perfume das plantas e o cheiro da terra. Tem semente, galho, sino que toca e anuncia a beleza da vida.',
    status: 1,
    usuario: {
      id: 10,
      email: 'juliano@outlook.com.br',
      nome: 'Juliano Filho',
      telefone: '41937485945',
      firebase_uid: 1,
      id_praca: 1,
      id_frequencia_notificacao: 1,
    },
    categoria: {
      nome: 'Natureza',
      ativo: 1,
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: null,
    },
    arquivos: [
      {
        id: 1,
        id_envio: 2,
        path:
          'https://spguia.melhoresdestinos.com.br/system/fotos_local/fotos/27928/show/jardim-botanico-de-curitiba.jpg',
        status: 1,
        tipo: 1,
        created_at: new Date(),
        updated_at: new Date(),
        deleted_at: null,
      },
      {
        id: 2,
        id_envio: 2,
        path:
          'https://www.greenme.com.br/images/viajar/jardim-botanico-curitiba.jpg',
        status: 1,
        tipo: 1,
        created_at: new Date(),
        updated_at: new Date(),
        deleted_at: null,
      },
    ],
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: null,
  },

  {
    id: 3,
    id_usuario_app: 3,
    id_categoria_envio: 2,
    titulo: 'Parque Tanguá',
    descricao: 'O parque mais bonito de Curitiba',
    status: 1,
    usuario: {
      id: 3,
      email: 'André@outlook.com.br',
      nome: 'André Pai',
      telefone: '41545285123',
      firebase_uid: 1,
      id_praca: 2,
      id_frequencia_notificacao: 1,
    },
    categoria: {
      nome: 'Natureza',
      ativo: 1,
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: null,
    },
    arquivos: [
      {
        id: 1,
        id_envio: 3,
        path: 'http://www.curitiba-parana.net/fotos/parque-tangua.jpg',
        status: 1,
        tipo: 1,
        created_at: new Date(),
        updated_at: new Date(),
        deleted_at: null,
      },
    ],
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: null,
  },

  {
    id: 4,
    id_usuario_app: 5,
    id_categoria_envio: 1,
    titulo: 'Nelson Piquet',
    descricao:
      'Desde sua aposentadoria, Piquet tem sido considerado um dos melhores pilotos da história da Fórmula 1, em várias avaliações de grande prestígio sobre automobilismo. Teve uma breve carreira no tênis antes de perder o interesse no esporte e, posteriormente, entrou no kart e escondeu sua identidade para evitar que seu pai descobrisse seu hobby.',
    status: 1,
    usuario: {
      id: 5,
      email: 'mariagonzalez@rpc.com.br',
      nome: 'Maria Gonzalez',
      telefone: '41993847384',
      firebase_uid: 4,
      id_praca: 3,
      id_frequencia_notificacao: 1,
    },
    categoria: {
      nome: 'Fórmula 1',
      ativo: 1,
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: null,
    },
    arquivos: [
      {
        id: 1,
        id_envio: 4,
        path:
          'http://cdn.images.autosport.com/f1greatestdrivers/mug/1952081700.jpg',
        status: 1,
        tipo: 1,
        created_at: new Date(),
        updated_at: new Date(),
        deleted_at: null,
      },
      {
        id: 1,
        id_envio: 4,
        path:
          'http://cdn.vocenarpc.com.br/production/medias/output/42-video-61a51730-a654-483a-9087-a2f11bdf35b95099213947785357602.mp4',
        status: 1,
        tipo: 0,
        created_at: new Date(),
        updated_at: new Date(),
        deleted_at: null,
      },
    ],
    created_at: new Date(),
    updated_at: new Date(),
    deleted_at: null,
  },
]
