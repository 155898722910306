export const styles = theme => ({
  thumbsContainer: {
    width: '100%',
    height: '100%',
  },
  
  thumb: {
    display: 'inline-flex',
    width: '100%',
    height: 125,
  },
  
  dropzone: {
    backgroundColor: '#eee',
    border: '2px dashed #ddd',
    padding: 8,
    height: 125,
    cursor: 'pointer',
  },
  
  thumbInner: {
    width: '100%',
    position: 'relative',
  },

  removeFilesButton: {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    height: 10,
    width: 35,
  },
  
  img: {
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'top',
  },
})