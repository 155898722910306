export const styles = theme => ({
  carousel: {
    position: "relative",
    backgroundColor: "#eee"
  },

  image: {
    maxWidth: "100%",
    minHeight: 200,
    maxHeight: 350,
    objectFit: "cover",
    objectPosition: "top",
    margin: "0 auto",
    display: "block"
  },

  previousImage: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 10,
    zIndex: 999
  },

  nextImage: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 10,
    zIndex: 999
  },

  imageButton: {
    height: 60,
    width: 60,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.8)"
    }
  },

  imageIcon: {
    fontSize: "28pt"
  },

  downloadButton: {
    width: "100%",
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "#006497",
    color: "#fff",
    height: 36,
    fontSize: "12pt",
    "&:hover": {
      backgroundColor: "#00496e"
    }
  },

  description: {
    color: "#444"
  },

  sentBy: {
    color: "#444"
  },

  sentAt: {
    color: "#444"
  }
});
