const drawerWidth = 240;

export const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: 998,
  },
  menuButton: {
    marginRight: 8,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  link: {
    textDecoration: 'none',
    color: '#fff',
  },

  navLink: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('xs')]: {
      padding: '0px 16px',
      fontSize: '12pt',
      fontWeight: 400,
    }
  },

  navLinkSelected: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('xs')]: {
      padding: '0px 16px',
      fontSize: '12pt',
      fontWeight: 600,
    }
  },

  navLinkMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12pt',
    },
  },

  logoRPC: {
    height: 50,
    marginRight: 8,
  },

  profileIcon: {
    position: 'absolute',
    right: 24,
  },
});