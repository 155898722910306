import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { getAtendendoComo, getStatus } from '../../constants/estabelecimentos'

const columns = [
  { id: 'cnpj', label: 'CNPJ' },
  {
    id: 'nome',
    label: 'Nome',
  },
  {
    id: 'categoria',
    label: 'Categoria',
    accessor: 'nome',
  },
  {
    id: 'atendendo_como',
    label: 'Atendendo como',
  },
  {
    id: 'email',
    label: 'E-mail',
  },
  {
    id: 'cidade',
    label: 'Cidade',
  },
  {
    id: 'telefone',
    label: 'Telefone',
  },
  {
    id: 'ativo',
    label: 'Ativo?',
  },
  {
    id: 'negocio',
    label: 'Negócio?',
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'edit',
    label: 'Editar',
  },
  {
    id: 'delete',
    label: 'Deletar'
  }
]

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
})

const TableEstabelecimentos = ({
  rows,
  setEstabelecimentoToUpdate,
  setEstabelecimentoToDelete,
  populateLookupField,
  pagination,
  setPagination,
  totalCount,
  onUpdateClick,
}) => {
  const classes = useStyles()

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, currentPage: newPage })
  }

  const handleChangeRowsPerPage = (event) => {
    setPagination({ currentPage: 0, pageSize: event.target.value })
  }

  const getTableCellValue = (row, column) => {
    if (column.id === 'cidade') {
      return row.enderecos[0][column.id]
    }
    if (column.id === 'telefone') {
      return row.enderecos[0][column.id]
    }
    if (column.id === 'atendendo_como') {
      return getAtendendoComo(row[column.id])
    }
    if (column.id === 'status') {
      return getStatus(row[column.id])
    }
    if (row[column.id] === true) {
      return 'Sim'
    } else if (row[column.id] === false) {
      return 'Não'
    }
    return row[column.id]
      ? column.accessor
        ? row[column.id][column.accessor]
        : row[column.id]
      : ''
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          {/* <TableContainer className={classes.container}> */}
          <Table aria-label='table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      if (column.id === 'edit') {
                        return (
                          <TableCell key={column.id}>
                            <IconButton
                              onClick={() => setEstabelecimentoToUpdate(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        )
                      }

                      if (column.id === 'delete') {
                        return (
                          <TableCell key={column.id}>
                            <IconButton
                              onClick={() => setEstabelecimentoToDelete(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell key={column.id}>
                          {getTableCellValue(row, column)}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {/* </TableContainer> */}
          <TablePagination
            rowsPerPageOptions={[3, 10, 20, 25, 50]}
            component='div'
            count={totalCount || 0}
            rowsPerPage={pagination.pageSize}
            page={pagination.currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default TableEstabelecimentos
