import React from 'react'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, LinearProgress, Typography } from '@material-ui/core'
import { pracas } from '../../constants/selectOptions'

const DialogCapaDeleteComponent = (props) => {
  const { 
    handleDelete,
    feed,
    isOpen,
    handleClose,
    dialogCapaDeleteState,
    setDialogCapaDeleteState,
    idsPracas,
    statusRequestFeed,
  } = props

  const handlePracaChange = id => event => {
    let pracas = dialogCapaDeleteState.selected_ids_pracas

    if (event.target.checked) {
      pracas.push(id)
    } else {
      pracas.splice(pracas.indexOf(id), 1)
    }
    setDialogCapaDeleteState({ ...dialogCapaDeleteState, selected_ids_pracas: pracas })
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-capa"
    >
      <DialogTitle id="dialog-capa">Excluir Conteúdo</DialogTitle>

      {!statusRequestFeed.done &&
        <div style={{ position: 'relative' }}>
          <LinearProgress 
            style={{ 
              position: 'absolute', bottom: 0, top: 0, left: 0, right: 0 
            }}
          />
        </div>
      }

      {dialogCapaDeleteState.id_feed &&
      <DialogContent>
        <DialogContentText id="dialog-capa-description">
          Essa operação é irreversível. Você tem certeza que deseja excluir este registro?
        </DialogContentText>
        <Typography paragraph>
          {feed.titulo}
        </Typography>

        <Grid container>
          {/* <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={checkAllComportamentos || feed.ids_pracas.length === 9}
                  onChange={handleCheckboxChange('', (checkAllComportamentos || feed.ids_pracas.length === 9) ? 'uncheckAll' : 'checkAll' )} 
                  value={(checkAllComportamentos || feed.ids_pracas.length === 9)} 
                  disabled={isOpen === 'changeComportamento'}
                />
              }
              label={(checkAllComportamentos || feed.ids_pracas.length === 9) ? 'Desmarcar Todos' : 'Marcar todos'}
            />
          </Grid> */}
          {(feed.id ? pracas.filter(praca => idsPracas.includes(praca.id)) : pracas).map(praca => (
            <Grid item xs={12} sm={6} md={4} key={praca.id}>
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={dialogCapaDeleteState.selected_ids_pracas.includes(praca.id)}
                    onChange={handlePracaChange(praca.id)} 
                    value={praca.id} 
                  />
                }
                label={praca.nome}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      }

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>

        <Button onClick={handleDelete} color="primary">
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogCapaDeleteComponent