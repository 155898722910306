import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import ChipInputComponent from '../ChipInputComponent'
import DatePickerComponent from '../DatePickerComponent'

const DialogFotosEVideosSearchComponent = props => {
  const [chipInput, setChipInput] = useState('')

  const {
    dialogFotosEVideosSearchState,
    setDialogFotosEVideosSearchState,
    handleSearch,
    handleClose,
    isOpen,
    categoriasEnvio,
  } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='dialog-fotosEVideos'
    >
      <DialogTitle id='dialog-fotosEVideos'>Filtrar Fotos e Vídeos</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id={'id_envio'}
              label='ID do Envio'
              value={dialogFotosEVideosSearchState.params.id_envio}
              onChange={event =>
                setDialogFotosEVideosSearchState({
                  ...dialogFotosEVideosSearchState,
                  params: {
                    ...dialogFotosEVideosSearchState.params,
                    id_envio: event.target.value,
                  },
                })
              }
              margin='normal'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id={'id_categoria_envio'}
              select
              label='Categoria de Envio'
              value={dialogFotosEVideosSearchState.params.id_categoria_envio}
              onChange={event =>
                setDialogFotosEVideosSearchState({
                  ...dialogFotosEVideosSearchState,
                  params: {
                    ...dialogFotosEVideosSearchState.params,
                    id_categoria_envio: event.target.value,
                  },
                })
              }
              margin='normal'
              fullWidth
            >
              {categoriasEnvio.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <ChipInputComponent
              value={chipInput}
              chips={dialogFotosEVideosSearchState.params.palavras_chave}
              onChipsChange={value =>
                setDialogFotosEVideosSearchState({
                  ...dialogFotosEVideosSearchState,
                  params: {
                    ...dialogFotosEVideosSearchState.params,
                    palavras_chave: value,
                  },
                })
              }
              onValueChange={value => setChipInput(value)}
              placeholder='Palavras-chave'
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <TextField
              id={'telespectador'}
              label='Telespectador'
              value={dialogFotosEVideosSearchState.params.telespectador}
              onChange={event =>
                setDialogFotosEVideosSearchState({
                  ...dialogFotosEVideosSearchState,
                  params: {
                    ...dialogFotosEVideosSearchState.params,
                    telespectador: event.target.value,
                  },
                })
              }
              margin='normal'
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6}>
            <DatePickerComponent
              onChange={value =>
                setDialogFotosEVideosSearchState({
                  ...dialogFotosEVideosSearchState,
                  params: {
                    ...dialogFotosEVideosSearchState.params,
                    data_inicio: value,
                  },
                })
              }
              value={dialogFotosEVideosSearchState.params.data_inicio}
              id='data_inicio'
              label='Data Início'
              disablePast={false}
              disableFuture={false}
              withoutMargins
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePickerComponent
              onChange={value =>
                setDialogFotosEVideosSearchState({
                  ...dialogFotosEVideosSearchState,
                  params: {
                    ...dialogFotosEVideosSearchState.params,
                    data_fim: value,
                  },
                })
              }
              value={dialogFotosEVideosSearchState.params.data_fim}
              id='data_fim'
              label='Data Fim'
              disablePast={false}
              disableFuture={false}
              withoutMargins
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 8 }}>
            <FormControl style={{ width: '100%' }}>
              <RadioGroup
                aria-label='publicacao-galeria'
                name='publicacao-galeria1'
                value={dialogFotosEVideosSearchState.params.publicado}
                onChange={event =>
                  setDialogFotosEVideosSearchState({
                    ...dialogFotosEVideosSearchState,
                    params: {
                      ...dialogFotosEVideosSearchState.params,
                      publicado: event.target.value,
                    },
                  })
                }
              >
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      value='todos'
                      control={<Radio />}
                      label='Todos'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      value='publicado'
                      control={<Radio />}
                      label='Publicado'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      value='nao-publicados'
                      control={<Radio />}
                      label='Não publicados'
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>

            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={dialogFotosEVideosSearchState.params.publicado}
                  onChange={event =>
                    setDialogFotosEVideosSearchState({
                      ...dialogFotosEVideosSearchState,
                      params: {
                        ...dialogFotosEVideosSearchState.params,
                        publicado: event.target.checked,
                      },
                    })
                  }
                  value='publicado'
                />
              }
              label='Publicado'
            /> */}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Cancelar
        </Button>

        <Button onClick={handleSearch} color='primary'>
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogFotosEVideosSearchComponent
