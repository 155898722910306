import React from 'react'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Typography } from '@material-ui/core'

const DialogCategoriasDeleteComponent = props => {
  const {
    isOpen,
    handleClose,
    categoria,
    handleDelete,
    handleCheckboxChange,
  } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={!!isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-categoria-delete"
    >
      <DialogTitle id="dialog-categoria-delete">
        {categoria.ativo ? 'Desativar Categoria' : 'Ativar Categoria'}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="dialog-categoria-delete">
          Você tem certeza que deseja {categoria.ativo ? 'desativar' : 'ativar'} este registro?
        </DialogContentText>
        <Grid container>
          {categoria.pracas && 
            categoria.pracas.map(praca => (
              <Grid item xs={12} sm={6} md={4} key={praca.id}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={categoria.pracas ? categoria.ids_pracas.includes(praca.id) : false}
                      onChange={handleCheckboxChange(praca.id, 'ids_pracas')} 
                      value={praca.id} 
                    />
                  }
                  label={praca.nome}
                />
              </Grid>
            ))
          }
        </Grid>
        <br />
        <Typography paragraph>
          {categoria.nome}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>

        <Button onClick={handleDelete} color="primary">
          {categoria.ativo ? 'Desativar' : 'Ativar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogCategoriasDeleteComponent