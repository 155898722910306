import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useRequest } from '../../hooks'
import { SnackbarContext } from '../../ContextProvider'
import { initialFeed } from '../../constants/initialConstants'
import LookupCategorias from '../Lookups/LookupCategorias'
import DialogCapaComponent from '../../components/Capa/DialogCapaComponent'

import { CDN_FEED_BASE_URL } from '../../constants/api'

const DialogCapa = (props) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const [feedState, setFeedState] = useState(initialFeed)
  const [checkAllComportamentos, setCheckAllComportamentos] = useState(false)
  const [isLookupCategoriasOpen, setIsLookupCategoriasOpen] = useState(false)
  const [photoDate, setPhotoDate] = useState(Date.now())

  const {
    isOpen,
    setIsOpen,
    feedId,
    setFeedToUpdate,
    setFeedToChangeComportamento,
    fetchDestaques,
    fetchFixos,
    fetchBreaking,
    fetchConteudoAutomatico,
  } = props

  const [feed, statusRequestFeed, fetchFeed] = useRequest(
    [],
    {
      url: `feed/${feedId}`,
      method: 'get',
    },
    null
  )

  const [, , gerarLinkS3] = useRequest(
    {},
    {
      url: 'feed/foto',
      method: 'post',
      data: feedState.imagem_upload ? 
        { 
          name: photoDate + '_' + feedState.imagem_upload[0].name, 
          type: feedState.imagem_upload[0].type 
        } : ''
    },
    null,
    {
      onComplete: async response => {
        await axios.put(
          response.url, 
          feedState.imagem_upload[0],
          { 
            headers: {
              'Content-Type': feedState.imagem_upload[0].type
            }
          }
        )
        fetchDestaques()
        fetchFixos()
        fetchBreaking()
        fetchConteudoAutomatico()
        setPhotoDate(Date.now())
        handleClose()
      }
    }
  )

  const [, , create] = useRequest(
    {},
    {
      url: 'feed',
      method: 'post',
      data: { 
        ...feedState, 
        imagem: 
          feedState.imagem_upload ? 
            `${CDN_FEED_BASE_URL}${photoDate}_${feedState.imagem_upload[0].name}` : 
              (feedState.imagem_alt ? 
                feedState.imagem_alt : 
                feedState.imagem
              )
      }
    },
    null,
    {
      onComplete: response => {
        if (response.id) {
          if (feedState.imagem_upload) {
            gerarLinkS3()
          } else {
            fetchDestaques()
            fetchFixos()
            fetchBreaking()
            fetchConteudoAutomatico()
            setPhotoDate(Date.now())
            handleClose()
          }
          showSnackbar('Conteúdo criado com sucesso!', 'success')
        } else {
          showSnackbar('Por favor, ' + response[0], 'error')
        }
      }
    }
  )

  const [, , update] = useRequest(
    {},
    {
      url: 'feed/atualizar/dados',
      method: 'put',
      data: { 
        ...feedState, 
        imagem: 
          feedState.imagem_upload ? 
            `${CDN_FEED_BASE_URL}${photoDate}_${feedState.imagem_upload[0].name}` : 
              (feedState.imagem_alt ? 
                feedState.imagem_alt : 
                feedState.imagem
              )
      }
    },
    null,
    {
      onComplete: response => {
        if (response.id) {
          if (feedState.imagem_upload) {
            gerarLinkS3()
          } else {
            fetchDestaques()
            fetchFixos()
            fetchBreaking()
            fetchConteudoAutomatico()
            setPhotoDate(Date.now())
            handleClose()
          }
          showSnackbar('Conteúdo alterado com sucesso!', 'success')
        } else {
          showSnackbar(response[0], 'error')
        }
      }
    }
  )

  const [, , changeComportamento] = useRequest(
    {},
    {
      url: 'feed/atualizar/comportamento',
      method: 'put',
      data: feedState
    },
    null,
    {
      onComplete: () => {
        handleClose()
        showSnackbar('Comportamento de conteúdo alterado com sucesso!', 'success')
        fetchDestaques()
        fetchFixos()
        fetchBreaking()
        fetchConteudoAutomatico()
        setPhotoDate(Date.now())
      }
    }
  )

  useEffect(() => {
    if (feedId) {
      fetchFeed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedId])

  useEffect(() => {
    if (feed.id) {
      let ids_pracas = []
      let pracas_comportamentos = []
      let data_vigencia_inicio
      let data_vigencia_fim

      const feedsWithComportamento = feed.feeds_praca.filter(el => el.feed_praca_comportamento_feed.length > 0)
      
      if (feedsWithComportamento[0]) {
        data_vigencia_inicio = feedsWithComportamento[0].feed_praca_comportamento_feed[0].data_vigencia_inicio

        data_vigencia_fim = feedsWithComportamento[0].feed_praca_comportamento_feed[0].data_vigencia_fim
      }
      
      feed.feeds_praca.map(el => ids_pracas.push(el.id_praca))

      feed.feeds_praca.map(el => pracas_comportamentos.push(
        { 
          id_praca: el.id_praca, 
          id_comportamento: el.feed_praca_comportamento_feed[0] ? el.feed_praca_comportamento_feed[0].id_comportamento_feed : 0
        }
      ))
      
      setFeedState({ 
        ...feed,
        imagem_upload: '',
        ids_pracas: ids_pracas, 
        pracas_comportamentos: pracas_comportamentos,
        titulo: feed.titulo_alt ? feed.titulo_alt : feed.titulo,
        data_vigencia_inicio: data_vigencia_inicio,
        data_vigencia_fim: data_vigencia_fim,
      })
    }
  }, [feed])


  const handleClose = () => {
    setFeedToUpdate({})
    setFeedToChangeComportamento({})
    setFeedState(initialFeed)
    setCheckAllComportamentos(false)
    setIsOpen(false)
  }
  
  const handleCreate = () => {
    create()
  }

  const handleUpdate = () => {
    isOpen === 'edit' ? update() : changeComportamento()
  }

  const handleLinkSelecionado = link => {
    setFeedState({ 
      ...feedState, 
      url: `vocenarpc://enviodemidia/${link.id}`, 
      url_nome: link.nome, 
      id_categoria_envio: link.id 
    })
    setIsLookupCategoriasOpen(false)
  }

  return (
    <div>
      <DialogCapaComponent 
        handleCreateOrUpdate={feedId ? handleUpdate : handleCreate}
        actionTitle={feedId ? 'Atualizar' : 'Criar'}
        handleClose={handleClose}
        isOpen={isOpen}
        feed={feedState}
        setFeed={setFeedState}
        setIsLookupCategoriasOpen={setIsLookupCategoriasOpen}
        checkAllComportamentos={checkAllComportamentos}
        setCheckAllComportamentos={setCheckAllComportamentos}
        statusRequestFeed={statusRequestFeed}
      />

      <LookupCategorias
        isOpen={isLookupCategoriasOpen}
        handleClose={() => setIsLookupCategoriasOpen(false)}
        handleLinkSelecionado={handleLinkSelecionado}
      />
    </div>
  )
}

export default DialogCapa