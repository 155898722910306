export const styles = theme => ({
  root: {
    position: "sticky",
    top: "50px",
    zIndex: 1,
    backgroundColor: "#f7f7f7"
  },

  lookupRoot: {
    position: "sticky",
    top: "-8px",
    zIndex: 1,
    backgroundColor: "#f7f7f7"
  },

  textField: {
    width: "100%",
    // marginTop: 16,
    marginBottom: 0
  },

  searchRoot: {
    padding: "0px 4px",
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    boxShadow: "inset 0px 0px 0px 1px #ccc"
  },

  searchInput: {
    marginLeft: 8,
    flex: 1
  },

  searchIconButton: {
    padding: 8
  },

  searchDivider: {
    width: 1,
    height: 28,
    margin: 4
  },

  newButton: {
    width: "100%",
    marginTop: 8,
    height: 40,
    backgroundColor: "#26C1C3",
    borderRadius: 0,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1f9fa1"
    }
  },

  clearButton: {
    width: "100%",
    marginTop: 8,
    height: 40,
    backgroundColor: "#c38426",
    borderRadius: 0,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#a66f1e"
    }
  }
});
