import React from 'react'
import { Grid } from '@material-ui/core'
import { renderCardImage } from '../../helpers/capa'
import CardCapa from './CardCapa'

const BreakingCapa = (props) => {
  const {
    breaking, 
    setFeedToUpdate, 
    setFeedToDelete,
    setFeedToChangeComportamento,
    setFeedNotificacao,
  } = props

  return (
    <Grid container alignItems='stretch' style={{ marginTop: 24 }}>
      <Grid item xs={12} md={4}>
        <img 
          style={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top',
            height: '100%',
          }}
          src={renderCardImage(breaking)} 
          alt={breaking.titulo} />
      </Grid>

      <Grid item xs={12} md={8}>
        <CardCapa 
          value={breaking}
          setFeedToUpdate={setFeedToUpdate}
          setFeedToDelete={setFeedToDelete}
          setFeedToChangeComportamento={setFeedToChangeComportamento}
          setFeedNotificacao={setFeedNotificacao}
          isBreakingNews
        />
      </Grid>
    </Grid>
  )
}

export default BreakingCapa