export const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },

  table: {
    width: "100%", 
    tableLayout: "auto"
  },
  
  tableWrapper: {
    overflowX: 'auto',
  },
});