import React from 'react'
import moment from 'moment'
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core'

import videoThumbnail from '../../assets/images/video-thumbnail.png'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../../styles/js/cardsFotosEVideos'
import InfiniteScroll from 'react-infinite-scroll-component'

const CardsFotosEVideos = props => {
  const { classes, fotosEVideos, setFotoEVideoToBeOpened,fetchMoreData } = props

  const handleCardClick = fotoEVideo => event => {
    if (
      event.target.tagName === 'BUTTON' ||
      event.target.tagName === 'path' ||
      event.target.tagName === 'svg'
    ) {
      return false
    } else {
      setFotoEVideoToBeOpened(fotoEVideo)
    }
  }

  return (
    <InfiniteScroll
    dataLength={fotosEVideos.length}
    next={fetchMoreData}
    hasMore={true}
    loader={<h4>Carregando...</h4>}
  >
    <Grid container spacing={2}>
      {fotosEVideos.map(fotoEVideo => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={fotoEVideo.id}>
          <Card
            className={classes.card}
            elevation={0}
            onClick={handleCardClick(fotoEVideo.id)}
          >
            <CardMedia
              className={classes.cardImage}
              image={fotoEVideo.path ? fotoEVideo.path : videoThumbnail}
              title={fotoEVideo.titulo}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h6'>{fotoEVideo.titulo}</Typography>

                  <Typography paragraph className={classes.cardSentBy}>
                    Enviada por{' '}
                    <span style={{ fontWeight: 500 }}>
                      {fotoEVideo.usuario ? fotoEVideo.usuario.nome : 'Anônimo'}
                    </span>
                  </Typography>

                  <Typography paragraph className={classes.cardSentAt}>
                    <span>{moment(fotoEVideo.createdAt).calendar()}</span>
                  </Typography>

                  {fotoEVideo.categoria && (
                    <Grid container>
                      <Chip label={fotoEVideo.categoria.nome} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    </InfiniteScroll>
  )
}

export default withStyles(styles)(CardsFotosEVideos)
