import React, { useState, useEffect } from 'react'
import { useRequest } from '../../hooks'
import { initialDialogEstabelecimentoSearchState } from '../../constants/initialConstants'
import DialogEstabelecimentosSearchComponent from '../../components/Estabelecimentos/DialogEstabelecimentosSearchComponent'

const DialogEstabelecimentosSearch = (props) => {
  const [
    dialogEstabelecimentosSearchState,
    setDialogEstabelecimentosSearchState,
  ] = useState(initialDialogEstabelecimentoSearchState)

  const { isOpen, setIsOpen, searchEstabelecimentos } = props

  const [
    estabelecimentosCategorias,
    statusRequestEstabelecimentosCategorias,
    fetchEstabelecimentosCategorias,
  ] = useRequest(
    [],
    {
      url: 'estabelecimentos/categorias',
      method: 'get',
    },
    null
  )

  useEffect(() => {
    if (isOpen) {
      fetchEstabelecimentosCategorias()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSearch = () => {
    searchEstabelecimentos(dialogEstabelecimentosSearchState)
    handleClose()
  }

  return (
    <DialogEstabelecimentosSearchComponent
      dialogEstabelecimentosSearchState={dialogEstabelecimentosSearchState}
      setDialogEstabelecimentosSearchState={
        setDialogEstabelecimentosSearchState
      }
      handleSearch={handleSearch}
      handleClose={handleClose}
      isOpen={isOpen}
      estabelecimentosCategorias={
        estabelecimentosCategorias ? estabelecimentosCategorias : []
      }
    />
  )
}

export default DialogEstabelecimentosSearch
