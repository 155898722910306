import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import CardCapa from './CardCapa'

const AutomaticCardsCapa = (props) => {
  const {
    conteudoAutomatico,
    setFeedToUpdate,
    setFeedToDelete,
    setFeedToChangeComportamento,
    setFeedNotificacao,
    searchParams,
  } = props

  const renderTitle = () => {

    if (!searchParams) {
      return (
        <Typography variant='h6' style={{ margin: '16px 0px' }} >
          Feed Automático
        </Typography>
      )
    } else if (!searchParams.buscaSimples) {
      const { params } = searchParams
      return (
        <div style={{ marginBottom: 16 }}>
          {params.palavras_chave.length > 0 && 
            <Typography variant='h6' style={{ margin: '16px 0px 0px 0px' }} >
             <span style={{ fontWeight: 400 }}>Buscando por: </span>{params.palavras_chave.join(', ')}
            </Typography>
          }
          {(params.data_inicio || params.data_fim) && 
            <Typography variant='body2'>
              {
                params.data_inicio ?
                  <span>{params.data_inicio.format('DD/MM/YYYY')}</span> : 
                  'Data não especificada'
              }
              <span style={{ paddingLeft: 8, paddingRight: 8 }}>---</span>
              {
                params.data_fim ?
                  <span>{params.data_fim.format('DD/MM/YYYY')}</span> : 
                  'Data não especificada'
              }
            </Typography>
          }
        </div>
      )

    } else if (searchParams.buscaSimples) {
      const { params } = searchParams
      return (
        <Typography variant='h6' style={{ margin: '16px 0px' }}>
          <span style={{ fontWeight: 400 }}>Buscando por: </span>{params.palavras_chave}
        </Typography>
      )

    }
  }

  return (
    <Grid container alignItems='stretch'>
      <Grid item xs={12}>
        {renderTitle()}
      </Grid>
      
      <Grid container spacing={1}>
        {(conteudoAutomatico ? conteudoAutomatico : []).map((value, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <CardCapa 
              showImage
              value={value}
              setFeedToUpdate={setFeedToUpdate}
              setFeedToDelete={setFeedToDelete}
              setFeedToChangeComportamento={setFeedToChangeComportamento}
              setFeedNotificacao={setFeedNotificacao}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default AutomaticCardsCapa