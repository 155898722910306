export const styles = theme => ({
  textField: {
    width: 'calc(100% - 30px)',
    marginLeft: 15,
    marginRight: 15,
  },

  chipsArea: {
    width: '100%',
    height: 65,
    backgroundColor: '#f3f3f3',
    overflowY: 'auto',
    border: '1px solid #ddd',
  },

  chip: {
    margin: theme.spacing(0.5),
  },
});