import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Pagination from "material-ui-flat-pagination"

import { useRequest } from '../../hooks'

import HeaderActions from '../../components/HeaderActions'
import DraggableCardsCapa from '../../components/Capa/DraggableCardsCapa'
import AutomaticCardsCapa from '../../components/Capa/AutomaticCardsCapa'
import BreakingCapa from '../../components/Capa/BreakingCapa'

import DialogCapa from './DialogCapa'
import DialogCapaDelete from './DialogCapaDelete'
import DialogCapaSearch from './DialogCapaSearch'
import DialogNotificacoes from '../Notificacoes/DialogNotificacoes'

const Capa = (props) => {
  const [pagination, setPagination] = useState({ currentPage: 0, pageSize: 28 })
  const [praca, setPraca] = useState(9)
  const [searchParams, setSearchParams] = useState(false)

  const [destaquesState, setDestaquesState] = useState([])
  const [fixosState, setFixosState] = useState([])

  const [isDialogOpen,       setIsDialogOpen] =       useState(false)
  const [isDialogDeleteOpen, setIsDialogDeleteOpen] = useState(false)
  const [isDialogSearchOpen, setIsDialogSearchOpen] = useState(false)
  const [isDialogNotificacaoOpen, setIsDialogNotificacaoOpen] = useState(false)

  const [feedToUpdate, setFeedToUpdate] = useState({})
  const [feedToDelete, setFeedToDelete] = useState({})
  const [feedToChangeComportamento, setFeedToChangeComportamento] = useState({})
  const [feedNotificacao, setFeedNotificacao] = useState({})
  const [changeComportamentoState, setChangeComportamentoState] = useState({})

  const [breaking, statusRequestBreaking, fetchBreaking] = useRequest(
    [],
    {
      url: `feed/listar-especial/${praca}/1`,
      method: 'get',
    },
    null
  )

  const [destaques, statusRequestDestaques, fetchDestaques] = useRequest(
    [],
    {
      url: `feed/listar-especial/${praca}/2`,
      method: 'get',
    },
    null
  )

  const [fixos, statusRequestFixos, fetchFixos] = useRequest(
    [],
    {
      url: `feed/listar-especial/${praca}/3`,
      method: 'get',
    },
    null
  )

  const [conteudoAutomatico, statusRequestConteudoAutomatico, fetchConteudoAutomatico] = useRequest(
    [],
    {
      url: `feed/listar/${praca}`,
      method: 'get',
      params: searchParams ? { ...searchParams, ...pagination } : pagination,
    },
    null
  )

  const [, , changeComportamento] = useRequest(
    {},
    {
      url: 'feed/atualizar/ordem',
      method: 'put',
      data: { ...changeComportamentoState, id_praca: praca }
    },
    null,
    {
      onComplete: () => {
        fetchDestaques()
        fetchFixos()
      }
    }
  )

  useEffect(() => {
    if (changeComportamentoState.feeds) {
      changeComportamento()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeComportamentoState])

  useEffect(() => {
    fetchBreaking()
    fetchDestaques()
    fetchFixos()
    setPagination({ currentPage: 0, pageSize: 30 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [praca])

  useEffect(() => {
    fetchConteudoAutomatico()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  useEffect(() => {
    if (Object.keys(feedNotificacao).length) {
      setIsDialogNotificacaoOpen('createFeedNotificacao')
    }
  }, [feedNotificacao])

  useEffect(() => {
    if (Object.keys(feedToUpdate).length) {
      setIsDialogOpen('edit')
    }
  }, [feedToUpdate])

  useEffect(() => {
    if (Object.keys(feedToDelete).length) {
      setIsDialogDeleteOpen(true)
    }
  }, [feedToDelete])

  useEffect(() => {
    if (Object.keys(feedToChangeComportamento).length) {
      setIsDialogOpen('changeComportamento')
    }
  }, [feedToChangeComportamento])
  
  useEffect(() => {
    setDestaquesState(destaques)
  }, [destaques])

  useEffect(() => {
    setFixosState(fixos)
  }, [fixos])

  useEffect(() => {
    setPagination({ currentPage: 0, pageSize: 30 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const searchConteudo = (pesquisa) => {
    setSearchParams(pesquisa)
  }

  const clearPesquisa = () => {
    setSearchParams(false)
  }

  const handlePaginationClick = offset => {
    setPagination({ ...pagination, currentPage: offset / pagination.pageSize })
  }

  return (
    <Grid container style={{maxWidth: 1280, margin: '0 auto'}}>
      <Grid item xs={12}>
        <HeaderActions
          setIsDialogOpen={setIsDialogOpen}
          setIsDialogSearchOpen={setIsDialogSearchOpen}
          buttonActionName='Novo Conteúdo'
          praca={praca}
          setPraca={setPraca}
          search={searchConteudo}
          searchParams={searchParams}
          clearPesquisa={clearPesquisa}
          statusRequest={(!statusRequestBreaking.done || !statusRequestDestaques.done || !statusRequestFixos.done || !statusRequestConteudoAutomatico.done)}
          withPracas
        />
        {!searchParams &&
        <div>
          {breaking[0] && 
          <BreakingCapa 
            breaking={breaking[0]}
            setFeedToUpdate={setFeedToUpdate}
            setFeedToDelete={setFeedToDelete}
            setFeedToChangeComportamento={setFeedToChangeComportamento}
            setFeedNotificacao={setFeedNotificacao}
          />
          }
          <DraggableCardsCapa 
            destaques={destaquesState}
            setDestaques={setDestaquesState}
            fixos={fixosState}
            setFixos={setFixosState}
            setFeedToUpdate={setFeedToUpdate}
            setFeedToDelete={setFeedToDelete}
            setFeedToChangeComportamento={setFeedToChangeComportamento}
            setFeedNotificacao={setFeedNotificacao}
            setChangeComportamentoState={setChangeComportamentoState}
          />
        </div>
        }
        <AutomaticCardsCapa 
          conteudoAutomatico={conteudoAutomatico ? conteudoAutomatico : []}
          setFeedToUpdate={setFeedToUpdate}
          setFeedToDelete={setFeedToDelete}
          setFeedToChangeComportamento={setFeedToChangeComportamento}
          setFeedNotificacao={setFeedNotificacao}
          searchParams={searchParams}
        />

        {searchParams && 
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
              <Pagination
                limit={pagination.pageSize}
                offset={pagination.currentPage * pagination.pageSize}
                total={conteudoAutomatico.count ? conteudoAutomatico.count : 0}
                onClick={(e, offset) => handlePaginationClick(offset)}
                size='large'
              />
            </div>
          </Grid>
        }
      </Grid>

      <DialogCapa
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        feedId={feedToUpdate.id ? feedToUpdate.id : feedToChangeComportamento.id ? feedToChangeComportamento.id : ''}
        setFeedToUpdate={setFeedToUpdate}
        setFeedToChangeComportamento={setFeedToChangeComportamento}
        fetchDestaques={fetchDestaques}
        fetchFixos={fetchFixos}
        fetchBreaking={fetchBreaking}
        fetchConteudoAutomatico={fetchConteudoAutomatico}
      />

      <DialogCapaDelete 
        isOpen={isDialogDeleteOpen}
        setIsOpen={setIsDialogDeleteOpen}
        feedId={feedToDelete.id ? feedToDelete.id : ''}
        setFeedToDelete={setFeedToDelete}
        fetchDestaques={fetchDestaques}
        fetchFixos={fetchFixos}
        fetchBreaking={fetchBreaking}
        fetchConteudoAutomatico={fetchConteudoAutomatico}
      />

      <DialogNotificacoes
        isOpen={isDialogNotificacaoOpen}
        setIsOpen={setIsDialogNotificacaoOpen}
        feedId={feedNotificacao.id ? feedNotificacao.id : ''}
        setFeedNotificacao={setFeedNotificacao}
      />

      <DialogCapaSearch
        isOpen={isDialogSearchOpen}
        setIsOpen={setIsDialogSearchOpen}
        searchConteudo={searchConteudo}
      />
    </Grid>
  )
}

export default Capa