import React from 'react'
import { Chip, Grid, TextField } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/chipInputComponent'

const ChipInputComponent = (props) => {
  const { 
    classes, 
    onChipsChange,
    onValueChange,
    value, 
    chips,
    placeholder,
  } = props

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const chipAlreadyCreated = chips.find(chip => chip === value)

      if (!chipAlreadyCreated) {
        onChipsChange([...chips, value ])
        onValueChange('')
      }
    }
  }

  const handleDelete = chipToDelete => () => {
    const filteredChips = chips.filter(chip => chip !== chipToDelete)
    onChipsChange(filteredChips)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          value={value}
          onChange={event => onValueChange(event.target.value)}
          onKeyPress={event => handleKeyPress(event)}
          margin='normal'
          placeholder={placeholder}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.chipsArea}>
          {chips.map(chip => (
            <Chip
              key={chip}
              label={chip}
              onDelete={handleDelete(chip)}
              className={classes.chip}
            />
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ChipInputComponent)

// const [chipInput, setChipInput] = useState('')
// const [chips, setChips] = useState([])

// <ChipInputComponent 
//   value={chipInput}
//   chips={chips}
//   onChipsChange={value => setChips(value)}
//   onValueChange={value => setChipInput(value)}
// />