import React, { useState, useEffect } from 'react'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/header'

import { pracas } from '../constants/selectOptions'

const HeaderActions = props => {
  const [pesquisa, setPesquisa] = useState('')
  const {
    classes,
    setIsDialogOpen,
    setIsDialogSearchOpen,
    buttonActionName,
    praca,
    setPraca,
    searchParams,
    clearPesquisa,
    statusRequest,
    withoutAdvancedSearch,
    withPracas,
    withAll,
    populateLookupField,
  } = props

  useEffect(() => {
    if (!searchParams) {
      setPesquisa('')
    }
  }, [searchParams])

  const search = () => {
    if (pesquisa) {
      props.search({
        buscaSimples: true,
        currentPage: 0,
        pageSize: 18,
        params: {
          palavras_chave: [pesquisa],
        },
      })
    }
  }

  return (
    <Grid
      container
      spacing={2}
      className={populateLookupField ? classes.lookupRoot : classes.root}
    >
      {withPracas && (
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            id='praca'
            select
            className={classes.textField}
            value={praca}
            onChange={event => setPraca(event.target.value)}
            margin='dense'
            variant='outlined'
          >
            {withAll && (
              <MenuItem key='todos' value='todos'>
                Todas
              </MenuItem>
            )}
            {pracas.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        md={
          !withPracas && searchParams
            ? 6
            : withPracas && searchParams
            ? 3
            : !withPracas && !searchParams
            ? 9
            : 6
        }
        lg={
          !withPracas && searchParams
            ? 6
            : withPracas && searchParams
            ? 3
            : !withPracas && !searchParams
            ? 9
            : 6
        }
      >
        <Paper square className={classes.searchRoot}>
          {!withoutAdvancedSearch && (
            <IconButton
              className={classes.searchIconButton}
              aria-label='Menu'
              onClick={() => setIsDialogSearchOpen(true)}
            >
              <MoreVertIcon />
            </IconButton>
          )}
          {!withoutAdvancedSearch && (
            <Divider className={classes.searchDivider} />
          )}
          <InputBase
            className={classes.searchInput}
            placeholder='Procurar por...'
            inputProps={{ 'aria-label': 'Procurar' }}
            value={pesquisa}
            onChange={event => setPesquisa(event.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                search()
              }
            }}
          />
          <IconButton
            color='primary'
            className={classes.searchIconButton}
            aria-label='Directions'
            disabled={!pesquisa}
            onClick={() => search()}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>

      {searchParams && (
        <Grid item xs={12} md={3} lg={3}>
          <Button
            elevation={0}
            size='medium'
            variant='contained'
            color='secondary'
            className={classes.clearButton}
            onClick={clearPesquisa}
          >
            Limpar filtros
          </Button>
        </Grid>
      )}

      {buttonActionName && (
        <Grid item xs={12} md={3} lg={3}>
          <Button
            elevation={0}
            size='medium'
            variant='contained'
            color='primary'
            className={classes.newButton}
            onClick={() => setIsDialogOpen('create')}
          >
            {buttonActionName}
          </Button>
        </Grid>
      )}

      <Grid item xs={12}>
        {statusRequest && (
          <div style={{ position: 'relative' }}>
            <LinearProgress
              style={{
                position: 'absolute',
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(HeaderActions)
