import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import ChipInputComponent from '../ChipInputComponent'
import DatePickerComponent from '../DatePickerComponent'

const DialogEstabelecimentosSearchComponent = (props) => {
  const [chipInput, setChipInput] = useState('')

  const {
    dialogEstabelecimentosSearchState,
    setDialogEstabelecimentosSearchState,
    handleSearch,
    handleClose,
    isOpen,
    estabelecimentosCategorias,
  } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='dialog-fotosEVideos'
    >
      <DialogTitle id='dialog-fotosEVideos'>
        Filtrar Estabelecimentos
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id={'nome'}
              label='Nome do estabelecimento'
              value={dialogEstabelecimentosSearchState.params.nome}
              onChange={(event) =>
                setDialogEstabelecimentosSearchState({
                  ...dialogEstabelecimentosSearchState,
                  params: {
                    ...dialogEstabelecimentosSearchState.params,
                    nome: event.target.value,
                  },
                })
              }
              margin='normal'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id={'id_categoria_estabelecimento'}
              select
              label='Categoria de Estabelecimento'
              value={
                dialogEstabelecimentosSearchState.params
                  .id_categoria_estabelecimento
              }
              onChange={(event) =>
                setDialogEstabelecimentosSearchState({
                  ...dialogEstabelecimentosSearchState,
                  params: {
                    ...dialogEstabelecimentosSearchState.params,
                    id_categoria_estabelecimento: event.target.value,
                  },
                })
              }
              margin='normal'
              fullWidth
            >
              <MenuItem key={0} value={'todos'}>
                Todos
              </MenuItem>
              {estabelecimentosCategorias.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <ChipInputComponent
              value={chipInput}
              chips={dialogEstabelecimentosSearchState.params.palavras_chave}
              onChipsChange={(value) =>
                setDialogEstabelecimentosSearchState({
                  ...dialogEstabelecimentosSearchState,
                  params: {
                    ...dialogEstabelecimentosSearchState.params,
                    palavras_chave: value,
                  },
                })
              }
              onValueChange={(value) => setChipInput(value)}
              placeholder='Palavras-chave'
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <TextField
              id={'cidade'}
              label='Cidade'
              value={dialogEstabelecimentosSearchState.params.cidade}
              onChange={(event) =>
                setDialogEstabelecimentosSearchState({
                  ...dialogEstabelecimentosSearchState,
                  params: {
                    ...dialogEstabelecimentosSearchState.params,
                    cidade: event.target.value,
                  },
                })
              }
              margin='normal'
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <TextField
              id={'status'}
              select
              label='Status'
              value={
                dialogEstabelecimentosSearchState.params
                  .status
              }
              onChange={(event) =>
                setDialogEstabelecimentosSearchState({
                  ...dialogEstabelecimentosSearchState,
                  params: {
                    ...dialogEstabelecimentosSearchState.params,
                    status: event.target.value,
                  },
                })
              }
              margin='normal'
              fullWidth
            >
              <MenuItem key={0} value={'todos'}>
                Todos
              </MenuItem>
              <MenuItem key={0} value={'null'}>
                Vazio (sem status)
              </MenuItem>
              <MenuItem key={0} value={1}>
                Cadastro rejeitado
              </MenuItem>
              <MenuItem key={1} value={2}>
                Não autorizado em decreto
              </MenuItem>
              <MenuItem key={2} value={3}>
                Contactado
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dialogEstabelecimentosSearchState.params.ativo}
                  onChange={(event) =>
                    setDialogEstabelecimentosSearchState({
                      ...dialogEstabelecimentosSearchState,
                      params: {
                        ...dialogEstabelecimentosSearchState.params,
                        ativo: event.target.checked,
                      },
                    })
                  }
                  value='ativo'
                />
              }
              label='Ativo?'
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={dialogEstabelecimentosSearchState.params.negocio}
                  onChange={(event) =>
                    setDialogEstabelecimentosSearchState({
                      ...dialogEstabelecimentosSearchState,
                      params: {
                        ...dialogEstabelecimentosSearchState.params,
                        negocio: event.target.checked,
                      },
                    })
                  }
                  value='negocio'
                />
              }
              label='Negócio?'
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Cancelar
        </Button>

        <Button onClick={handleSearch} color='primary'>
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogEstabelecimentosSearchComponent
