export const API_BASE_URL = 'https://api.conteudo.vocenarpc.com.br/v1/'
export const APP_URL = 'https://conteudo.vocenarpc.com.br'
export const AUTHENTICATION_URL = 'https://auth.grpcom.com.br/auth/conteudo-vocenarpc?next=conteudo.vocenarpc.com.br'
export const CDN_FEED_BASE_URL = 'https://cdn.vocenarpc.com.br/production/feed/'

export default {
  API_BASE_URL,
  APP_URL,
  AUTHENTICATION_URL,
  CDN_FEED_BASE_URL
}
/* eslint-enable global-require */