import React, { useState } from 'react'
import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './styles/js/snackbarProvider'

import { SnackbarContext } from './ContextProvider.jsx'

const typeIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const initialSnackbarState = {
  open: false,
  message: '',
  type: 'success',
}

function SnackbarProvider(props) {
  const { classes, className } = props
  const [snackbarState, setSnackbarState] = useState(initialSnackbarState)
  const { message, type, open } = snackbarState

  const showSnackbar = (message, type) => {
    setSnackbarState({
      open: true,
      message,
      type,
    }) 
  }

  const snackbarContextValue = {
    showSnackbar
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarState({ ...snackbarState, open: false })
  }

  const Icon = typeIcon[type]

  return (
    <SnackbarContext.Provider value={snackbarContextValue}>
      {props.children}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <SnackbarContent
          className={classNames(classes[type], className)}
          aria-describedby="snackbar"
          message={
            <span id="snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconType)} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export default withStyles(styles)(SnackbarProvider)