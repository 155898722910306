import React from 'react'
import { Card, CardActions, CardContent, CardMedia, IconButton, Tooltip, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import LockIcon from '@material-ui/icons/Lock'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../../styles/js/cardsCapa'

import { getTagCategoriaColor, getTagTipoColor, renderCardImage, getExpirationDate, getTagEspecialPublicitario } from '../../helpers/capa'

const CardCapa = (props) => {
  const { 
    classes, 
    value, 
    setFeedToUpdate, 
    setFeedToDelete,
    setFeedToChangeComportamento,
    setFeedNotificacao,
    showImage,
    isBreakingNews,
  } = props
  
  let tagCategoriaColor = getTagCategoriaColor(value.id_categoria)
  let tagTipoColor = getTagTipoColor(value.id_feed_conteudo_tipo)
  let tagEspecialPublicitario = getTagEspecialPublicitario(value.url)

  return (
    <Card className={classes.card}>
      {showImage && 
      <CardMedia 
        className={classes.cardMedia}
        image={renderCardImage(value)}
        title="Imagem do Card"
      >
      </CardMedia>
      }

      <CardContent className={classes.cardContent}>
        {value.requer_autenticacao &&
          <div style={{ display: 'inline', position: 'absolute' }}>
            <LockIcon style={{ color: '#555' }}/>
          </div>
        }
        <div 
          className={classes.cardTag}
          style={{ backgroundColor: tagCategoriaColor, marginLeft: value.requer_autenticacao? 36 : 0 }}>
          {value.categoria.nome}
        </div>
        <div 
          className={classes.cardTag}
          style={{ backgroundColor: tagTipoColor }}>
          {value.feed_conteudo_tipo.nome}
        </div>
        {tagEspecialPublicitario &&
          <div 
            className={classes.cardTag}
            style={{ backgroundColor: '#FA0' }}>
            PUBLI
          </div>
        }

        {isBreakingNews && 
        <div>
          <Typography 
            variant='h6' 
            style={{ 
              fontWeight: 500, 
              marginBottom: 8,
            }}>
            Urgente
          </Typography>
        </div>
        }

        {isBreakingNews ?
        <div>
          <Typography variant='h6' style={{ fontWeight: 400, marginBottom: 8 }}>
            {value.titulo_alt ? value.titulo_alt : value.titulo}
          </Typography>
          <Typography paragraph style={{ color: '#666' }}>
            {value.resumo}
          </Typography>
        </div>
        :
        <Typography paragraph>
          {value.titulo_alt ? value.titulo_alt : value.titulo}
        </Typography>
        }
        <Typography variant='subtitle2' className={classes.cardExpiration}>
          {getExpirationDate(value)}
        </Typography>
      </CardContent>

      <CardActions className={classes.cardActions}>
        <Tooltip title='Criar Notificação'>
          <IconButton className={classes.cardAction} onClick={() => setFeedNotificacao(value)}>
            <NotificationsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Trocar Comportamento'>
          <IconButton className={classes.cardAction} onClick={() => setFeedToChangeComportamento(value)}>
            <SwapVertIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Excluir'>
          <IconButton className={classes.cardAction} onClick={() => setFeedToDelete(value)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Alterar'>
          <IconButton className={classes.cardAction} onClick={() => setFeedToUpdate(value)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

export default withStyles(styles)(CardCapa)