import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/ChevronLeft'
import NavigateAfterIcon from '@material-ui/icons/ChevronRight'
import CircularLoading from '../CircularLoading'
import { styles } from '../../styles/js/dialogFotosEVideosDetails'
import { withStyles } from '@material-ui/core'

const DialogFotosEVideosDetails = props => {
  const [arquivo, setArquivo] = useState([])

  const {
    classes,
    dialog,
    setIsOpen,
    setFotoEVideoToBeOpened,
    setArquivoState,
    statusRequestUpdateArquivo,
    statusRequestFotoEVideo,
  } = props

  useEffect(() => {
    if (dialog.data && dialog.data.id) {
      if (dialog.data.arquivos.length > 0) {
        setArquivo(dialog.data.arquivos[0])
      }
    }
  }, [dialog.data])

  const handleClose = () => {
    setFotoEVideoToBeOpened('')
    setIsOpen(false)
  }

  const handlePreviousImage = () => {
    const index = dialog.data.arquivos.indexOf(arquivo)

    if (typeof dialog.data.arquivos[index - 1] !== 'undefined') {
      setArquivo(dialog.data.arquivos[index - 1])
    } else {
      return
    }
  }

  const handleNextImage = () => {
    const index = dialog.data.arquivos.indexOf(arquivo)

    if (typeof dialog.data.arquivos[index + 1] !== 'undefined') {
      setArquivo(dialog.data.arquivos[index + 1])
    } else {
      return
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={dialog.isOpen}
      onClose={handleClose}
      aria-labelledby='dialog-fotoEVideo'
    >
      {dialog.data &&
        dialog.data.id &&
        (statusRequestFotoEVideo.done ? (
          <div>
            <DialogTitle id='dialog-fotoEVideo'>
              Detalhes de {dialog.data.titulo} | {dialog.data.id}
            </DialogTitle>

            <DialogContent>
              {arquivo && (
                <Grid container justifyContent='center'>
                  <Grid item xs={12}>
                    <div className={classes.carousel}>
                      {dialog.data.arquivos.findIndex(
                        obj => obj.id === arquivo.id
                      ) !== 0 && (
                        <div className={classes.previousImage}>
                          <IconButton
                            className={classes.imageButton}
                            onClick={handlePreviousImage}
                          >
                            <NavigateBeforeIcon className={classes.imageIcon} />
                          </IconButton>
                        </div>
                      )}

                      {!arquivo.tipo ? (
                        <img
                          src={arquivo.id ? arquivo.path : ''}
                          alt={dialog.data.titulo}
                          className={classes.image}
                        ></img>
                      ) : (
                        <video controls style={{ width: '100%', height: 350 }}>
                          <source src={arquivo.path} type='video/mp4' />
                          Seu navegador não suporta vídeo HTML5.
                        </video>
                      )}

                      {dialog.data.arquivos.findIndex(
                        obj => obj.id === arquivo.id
                      ) !==
                        dialog.data.arquivos.length - 1 && (
                        <div className={classes.nextImage}>
                          <IconButton
                            className={classes.imageButton}
                            onClick={handleNextImage}
                          >
                            <NavigateAfterIcon className={classes.imageIcon} />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              )}
              {arquivo && (
                <Grid container>
                  <Grid item xs={12}>
                    <IconButton
                      className={classes.downloadButton}
                      target='_blank'
                      download
                      href={arquivo.fullSize ? arquivo.fullSize : '#'}
                    >
                      Download
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              <br />
              <Grid container>
                <Grid item md={6} xs={12} style={{ marginBottom: 16 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={arquivo.publicado}
                        onChange={event =>
                          setArquivoState({
                            id: arquivo.id,
                            publicado: event.target.checked,
                          })
                        }
                        value='publicado'
                        color='primary'
                      />
                    }
                    label={
                      statusRequestUpdateArquivo.isLoading ? (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 16 }}
                        />
                      ) : (
                        'Publicado'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                  style={{ marginBottom: 8, marginTop: 4 }}
                >
                  <Typography
                    paragraph
                    style={{ float: 'right', marginBottom: 0, fontWeight: 500 }}
                  >
                    {dialog.data.arquivos.findIndex(
                      el => el.id === arquivo.id
                    ) + 1}
                    /{dialog.data.arquivos.length}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <br />
                  <Typography paragraph className={classes.description}>
                    Data de Envio:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {moment(dialog.data.createdAt).calendar()}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph className={classes.description}>
                    Enviado por:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {dialog.data.usuario
                        ? dialog.data.usuario.nome
                        : 'Anônimo'}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph className={classes.description}>
                    Descrição:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {dialog.data.descricao}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph className={classes.description}>
                    E-mail:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {dialog.data.usuario
                        ? dialog.data.usuario.email
                        : 'Anônimo'}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph className={classes.description}>
                    Praça:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {dialog.data.usuario
                        ? dialog.data.usuario.praca.nome
                        : 'N/A'}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph className={classes.description}>
                    Telefone:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {dialog.data.usuario &&
                      dialog.data.usuario.telefone !== null
                        ? dialog.data.usuario.telefone
                        : 'N/A'}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <br />
            </DialogContent>
          </div>
        ) : (
          <CircularLoading />
        ))}

      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(DialogFotosEVideosDetails)
