import React, { useState } from 'react'
import { initialDialogSearchState } from '../../constants/initialConstants'
import DialogCapaSearchComponent from '../../components/Capa/DialogCapaSearchComponent'

const DialogCapaSearch = (props) => {
  const [dialogCapaSearchState, setDialogCapaSearchState] = useState(initialDialogSearchState)

  const { 
    isOpen,
    setIsOpen,
    searchConteudo
  } = props

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSearch = () => {
    searchConteudo(dialogCapaSearchState)
    handleClose()
  }
  
  return (
    <DialogCapaSearchComponent
      dialogCapaSearchState={dialogCapaSearchState}
      setDialogCapaSearchState={setDialogCapaSearchState}
      handleSearch={handleSearch}
      handleClose={handleClose}
      isOpen={isOpen}
    />
  )
}

export default DialogCapaSearch