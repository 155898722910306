export const pracas = [
  {
    id: 1,
    nome: 'Cascavel',
    mnemonico: 'CAV',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 2,
    nome: 'Curitiba',
    mnemonico: 'CUR',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 3,
    nome: 'Foz do Iguaçu',
    mnemonico: 'FOZ',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 4,
    nome: 'Guarapuava',
    mnemonico: 'GAV',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 5,
    nome: 'Londrina',
    mnemonico: 'LON',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 6,
    nome: 'Maringá',
    mnemonico: 'MAR',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 7,
    nome: 'Noroeste',
    mnemonico: 'PR3',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 8,
    nome: 'Ponta Grossa',
    mnemonico: 'PGR',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 9,
    nome: 'Paraná',
    mnemonico: 'PRE',
    created_at: new Date(),
    updated_at: new Date(),
  },
]

export const fotosEVideosPracas = [
  {
    id: 1,
    nome: 'Cascavel',
    mnemonico: 'CAV',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 2,
    nome: 'Curitiba',
    mnemonico: 'CUR',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 3,
    nome: 'Foz do Iguaçu',
    mnemonico: 'FOZ',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 4,
    nome: 'Guarapuava',
    mnemonico: 'GAV',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 5,
    nome: 'Londrina',
    mnemonico: 'LON',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 6,
    nome: 'Maringá',
    mnemonico: 'MAR',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 7,
    nome: 'Noroeste',
    mnemonico: 'PR3',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 8,
    nome: 'Ponta Grossa',
    mnemonico: 'PGR',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 9,
    nome: 'Paraná',
    mnemonico: 'PRE',
    created_at: new Date(),
    updated_at: new Date(),
  },
]

export const categorias = [
  {
    id: 1,
    nome: 'G1',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 2,
    nome: 'GE',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 3,
    nome: 'GSHOW',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 4,
    nome: 'RPC',
    created_at: new Date(),
    updated_at: new Date(),
  },
]

export const comportamentosFeed = [
  {
    id: 1,
    descricao: 'Breaking',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 2,
    descricao: 'Destaque',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 3,
    descricao: 'Fixo',
    created_at: new Date(),
    updated_at: new Date(),
  },
]

export const conteudoTipos = [
  {
    id: 2,
    nome: 'Notícia',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: 1,
    nome: 'Interação',
    created_at: new Date(),
    updated_at: new Date(),
  },
]

export const interesses = [
  {
    id: 1,
    nome: 'Lorem ipsum',
  },
  {
    id: 2,
    nome: 'Ultrices',
  },
  {
    id: 3,
    nome: 'Sed vel',
  },
  {
    id: 4,
    nome: 'Vestibulum',
  },
  {
    id: 5,
    nome: 'Pellentesque',
  },
  {
    id: 6,
    nome: 'Quisque',
  },
  {
    id: 7,
    nome: 'Maecenas sodales',
  },
  {
    id: 8,
    nome: 'Integer dapibus massa',
  },
  {
    id: 9,
    nome: 'Cras volutpat',
  },
  {
    id: 10,
    nome: 'Curabitur nec',
  },
]

export const linksInternos = [
  {
    id: 1,
    titulo: 'Em Alta',
    link: 'vocenarpc://emalta',
  },
  {
    id: 2,
    titulo: 'Fale Conosco',
    link: 'vocenarpc://faleconosco',
  },
  {
    id: 3,
    titulo: 'Home',
    link: 'vocenarpc://home',
  },
  {
    id: 4,
    titulo: 'Login',
    link: 'vocenarpc://login',
  },
  {
    id: 5,
    titulo: 'Notificações',
    link: 'vocenarpc://notificacoes',
  },
  {
    id: 6,
    titulo: 'Notificações - Configurações',
    link: 'vocenarpc://notificacoes/config',
  },
  {
    id: 7,
    titulo: 'Notificações - Interesses',
    link: 'vocenarpc://notificacoes/interesses',
  },
  {
    id: 8,
    titulo: 'Perfil',
    link: 'vocenarpc://perfil',
  },
  {
    id: 9,
    titulo: 'Programação',
    link: 'vocenarpc://programacao',
  },
  {
    id: 10,
    titulo: 'Sua Região',
    link: 'vocenarpc://suaregiao',
  },
  {
    id: 11,
    titulo: 'Recicla',
    link: 'vocenarpc://recicla',
  },
]

export const estabelecimentos = {
  count: 3,
  rows: [
    {
      id: 1,
      cnpj: '11111111111',
      categoria_estabelecimento: {
        id: 1,
        nome: 'Restaurante',
      },
      nome: 'Nome1',
      email: 'teste',
      atendendo_como: {
        id: 1,
        nome: 'Delivery',
      },
      cidade: 'Curitiba',
      telefone: '(41) 99186-9834',
    },
    {
      id: 2,
      cnpj: '22222222222',
      categoria_estabelecimento: {
        id: 2,
        nome: 'Mercado',
      },
      nome: 'Nome2',
      email: 'teste',
      atendendo_como: {
        id: 2,
        nome: 'Delivery',
      },
      cidade: 'Curitiba',
      telefone: '(42) 99286-9834',
    },
    {
      id: 3,
      cnpj: '33333333333',
      categoria_estabelecimento: {
        id: 3,
        nome: 'Restaurante',
      },
      nome: 'Nome3',
      email: 'teste',
      atendendo_como: {
        id: 3,
        nome: 'Delivery',
      },
      cidade: 'Curitiba',
      telefone: '(43) 99386-9834',
    },
  ],
}
