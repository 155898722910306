import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

const DialogLookup = (props) => {
  const [selection, setSelection] = useState([])
  const { 
    Component, 
    title, 
    isOpen, 
    setIsOpen,
    setLookupField,
    lookupFilter,
    withSelect
  } = props

  const handleClose = () => {
    setIsOpen(false)
  }

  const handlePopulateLookupField = (row) => {
    setLookupField(row)
    handleClose()
  }

  const handleSelection = () => {
    setLookupField(selection)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth='lg'
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-capa"
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#f7f7f7' }}>
        {<Component 
          populateLookupField={handlePopulateLookupField}
          lookupFilter={lookupFilter}
          withSelect={withSelect}
          setSelection={setSelection}
        />}
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>Fechar</Button>
        {withSelect && 
        <Button color='primary' onClick={handleSelection}>Selecionar</Button>
        }
      </DialogActions>
    </Dialog> 
  )
}

export default DialogLookup
