import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, Grid, IconButton, InputAdornment, LinearProgress, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import LinkIcon from '@material-ui/icons/Link'
import SearchIcon from '@material-ui/icons/Search'
import DatePickerComponent from '../DatePickerComponent'
import DropzoneComponent from '../DropzoneComponent'
import { getPracaNameById } from '../../helpers/capa'
import { pracas, categorias, conteudoTipos } from '../../constants/selectOptions'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../../styles/js/dialog'
import DialogLinksInternos from '../../containers/DialogLinksInternos'

const DialogCapaComponent = (props) => { 
  const { 
    classes,
    feed,
    setFeed,
    handleCreateOrUpdate, 
    actionTitle,
    handleClose, 
    isOpen,
    setIsLookupCategoriasOpen,
    checkAllComportamentos,
    setCheckAllComportamentos,
    statusRequestFeed,
  } = props

  const [dialogLinksInternosOpen, setDialogLinksInternosOpen] = useState(false)
  const [imageOption, setImageOption] = useState('upload')
  const [applyComportamento, setApplyComportamento] = useState('')

  useEffect(() => {
    setFeed({ ...feed, imagem_upload: '', imagem_link: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageOption])

  useEffect(() => {
    if (applyComportamento || applyComportamento === 0) {
      const updatedFeed = feed
      updatedFeed.pracas_comportamentos.forEach(el => el.id_comportamento = applyComportamento)
      setFeed({ 
        ...updatedFeed
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyComportamento])

  const handleCheckboxChange = (id, nome) => event => {
    if (nome === 'checkAll') {
      let pracasComportamentos = []

      for (let i = 1; i <= 9; i++) { 
        pracasComportamentos.push({ id_praca: i, id_comportamento: 0 })
      }

      setFeed({ ...feed, ids_pracas: [1, 2, 3, 4, 5, 6, 7, 8, 9], pracas_comportamentos: pracasComportamentos})
      setCheckAllComportamentos(true)
      return
    }

    if (nome === 'uncheckAll') {
      setFeed({ ...feed, ids_pracas: [], pracas_comportamentos: [] })
      setCheckAllComportamentos(false)
      return
    }

    let checkboxes = feed[nome]
    let pracasComportamentos = feed.pracas_comportamentos

    if (event.target.checked) {
      checkboxes.push(id)
      pracasComportamentos.push({ id_praca: id, id_comportamento: 0 })
    } else {
      checkboxes.splice(checkboxes.indexOf(id), 1)
      pracasComportamentos = feed.pracas_comportamentos.filter(el => el.id_praca !== id)
    }

    setFeed({ 
      ...feed, 
      [nome]: checkboxes,
      pracas_comportamentos: pracasComportamentos
    })
  }

  const handleComportamentoChange = praca => event => {  
    const index = feed.pracas_comportamentos.findIndex((obj => obj.id_praca === praca))

    let pracasComportamentos = feed.pracas_comportamentos
    pracasComportamentos[index].id_comportamento = event.target.value

    setFeed({ ...feed, pracas_comportamentos: pracasComportamentos })
    setApplyComportamento('')
  }

  const handleChange = name => event => {
    setFeed({ ...feed, [name]: event.target.value })
  }

  const handleChipDelete = () => {
    setFeed({ ...feed, id_categoria_envio: null, url: '', url_nome: '' })
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={!!isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-capa"
      >
        <DialogTitle id="dialog-capa">
          {isOpen === 'edit' && `Editar Conteúdo ${feed.id ? `| ${feed.id}` : ''}`}
          {isOpen === 'create' && 'Novo Conteúdo'}
          {isOpen === 'changeComportamento' && 'Alterar Comportamento'}
        </DialogTitle>

        {(isOpen !== 'create' && !statusRequestFeed.done) &&
          <div style={{ position: 'relative' }}>
            <LinearProgress 
              style={{ 
                position: 'absolute', bottom: 0, top: 0, left: 0, right: 0 
              }}
            />
          </div>
        }

        <DialogContent>
          {isOpen === 'edit' &&
            <DialogContentText style={{ fontSize: '11pt', color: '#888' }}>
              As mudanças serão salvas para todas as praças que contêm este conteúdo.
            </DialogContentText>
          }

          {(isOpen === 'create' || isOpen === 'edit') &&
            <div>
              <RadioGroup
                aria-label="Tipos de Conteúdo"
                name="tipos_de_conteudo"
                value={`${feed.id_feed_conteudo_tipo}`}
                onChange={handleChange('id_feed_conteudo_tipo')}
              >
                <Grid container>
                  {conteudoTipos.map(conteudoTipo => (
                    <Grid item xs={12} sm={6} md={3} key={conteudoTipo.id}>
                      <FormControlLabel 
                        value={`${conteudoTipo.id}`} 
                        control={<Radio />} 
                        label={conteudoTipo.nome} 
                        key={conteudoTipo.id}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
              <br />
              <RadioGroup
                aria-label="Categorias"
                name="categorias"
                value={`${feed.id_categoria}`}
                onChange={handleChange('id_categoria')}
              >
                <Grid container>
                  {categorias.map(categoria => (
                    <Grid item xs={12} sm={6} md={3} key={categoria.id}>
                      <FormControlLabel 
                        value={`${categoria.id}`} 
                        control={<Radio />} 
                        label={categoria.nome} 
                        key={categoria.id}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>

              <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12}>
                  <TextField
                    id={'titulo'}
                    label="Título"
                    value={feed.titulo}
                    onChange={handleChange('titulo')}
                    margin="normal"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  {feed.id_categoria_envio ?
                    <Chip
                      color='primary'
                      label={feed.url_nome ? feed.url_nome : feed.url}
                      onDelete={handleChipDelete}
                      style={{ marginBottom: 8 }}
                    />
                  :
                    <TextField
                      id="url"
                      label="URL"
                      value={feed.url}
                      onChange={handleChange('url')}
                      margin="normal"
                      fullWidth
                      InputProps={{
                        endAdornment: 
                          <InputAdornment position="start">
                            <IconButton onClick={() => setDialogLinksInternosOpen(true)}>
                              <LinkIcon />
                            </IconButton>
                            <IconButton onClick={() => setIsLookupCategoriasOpen(true)}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>,
                      }}
                    />
                  }
                </Grid>

                {(feed.imagem_alt || feed.imagem) &&
                  <div style={{ marginRight: 8, marginLeft: 8, position: 'relative' }}>
                    <Fab
                      variant='round'
                      size='small'
                      color='secondary' 
                      className={classes.removeUploadedImage} 
                      onClick={() => setFeed({ ...feed, imagem: '', imagem_alt: '' })}>
                      <ClearIcon />
                    </Fab>
                    <Grid item xs={12}>
                      <img alt='imagem' className={classes.uploadedImage} src={feed.imagem_alt ? feed.imagem_alt : feed.imagem} />
                    </Grid>
                  </div>
                }

                {!feed.imagem_alt && !feed.imagem &&
                  <Grid item xs={12}>
                    <RadioGroup
                      aria-label="Imagem"
                      name="imagem"
                      value={imageOption || 'upload'}
                      onChange={event => setImageOption(event.target.value)}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={6} key='upload'>
                          <FormControlLabel 
                            value='upload'
                            control={<Radio />} 
                            label='Upload' 
                            key='upload'
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} key='link'>
                          <FormControlLabel 
                            value='link'
                            control={<Radio />} 
                            label='Link' 
                            key='link'
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                }

                {!feed.imagem_alt && !feed.imagem &&
                  <Grid item xs={12}>
                    {imageOption === 'upload' &&
                      <DropzoneComponent
                        onFileChange={value => setFeed({ ...feed, imagem_upload: value })}
                      />
                    }
                    {imageOption === 'link' &&
                      <TextField
                        id="imagem_link"
                        label="Link da Imagem"
                        value={feed.imagem_link || ''}
                        onChange={handleChange('imagem_link')}
                        margin="normal"
                        fullWidth
                      />
                    }
                  </Grid>
                }
                {(feed.imagem_upload || feed.imagem_link) &&
                  <Grid item xs={12} sm={6}>
                    <Typography paragraph className={classes.imagePreviewTitle}>
                      Preview de Destaque
                    </Typography>
                    <img
                      alt='preview-destaque'
                      src={feed.imagem_link ? feed.imagem_link : feed.imagem_upload[0].preview} 
                      className={classes.imageUploadDestaquesPreview} 
                    />
                  </Grid>
                }
                {(feed.imagem_upload || feed.imagem_link) &&
                  <Grid item xs={12} sm={6}>
                    <Typography paragraph className={classes.imagePreviewTitle}>
                      Preview de Feed/Breaking
                    </Typography>
                    <img
                      alt='preview-feed-breaking'
                      src={feed.imagem_link ? feed.imagem_link : feed.imagem_upload[0].preview} 
                      className={classes.imageUploadFixosPreview} 
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={feed.id_categoria_envio ? true : feed.requer_autenticacao}
                        onChange={event => feed.id_categoria_envio ? {} : setFeed({ ...feed, requer_autenticacao: event.target.checked })}
                        value={feed.id_categoria_envio ? true : feed.requer_autenticacao}
                      />
                    }
                    label='Requer autenticação do usuário?'
                  />
                </Grid>
              </Grid>
            </div>
          }

          <br />

          {(isOpen === 'create' || isOpen === 'changeComportamento') && 
            <div>
              <Typography variant='h6' className={classes.sectionTitle}>
                Praças
              </Typography>
            
              {isOpen === 'create' &&
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox 
                          checked={checkAllComportamentos || feed.ids_pracas.length === 9}
                          onChange={handleCheckboxChange('', (checkAllComportamentos || feed.ids_pracas.length === 9) ? 'uncheckAll' : 'checkAll' )} 
                          value={(checkAllComportamentos || feed.ids_pracas.length === 9)} 
                          disabled={isOpen === 'changeComportamento'}
                        />
                      }
                      label={(checkAllComportamentos || feed.ids_pracas.length === 9) ? 'Desmarcar Todos' : 'Marcar todos'}
                    />
                  </Grid>
                  {(isOpen === 'create' ? pracas : pracas.filter(praca => feed.ids_pracas.includes(praca.id))).map(praca => (
                    <Grid item xs={12} sm={6} md={4} key={praca.id}>
                      <FormControlLabel
                        control={
                          <Checkbox 
                            checked={feed.ids_pracas.includes(praca.id)}
                            onChange={handleCheckboxChange(praca.id, 'ids_pracas')} 
                            value={praca.id}
                            disabled={isOpen === 'changeComportamento'}
                          />
                        }
                        label={praca.nome}
                      />
                    </Grid>
                  ))}
                </Grid>
              }
              {feed.ids_pracas.length > 0 &&
                <Grid item xs={12} sm={9} key='applyToAll'>
                  <TextField
                    id="applyToAll"
                    select
                    label='Aplicar a todos'
                    value={applyComportamento}
                    onChange={event => setApplyComportamento(event.target.value)}
                    margin="normal"
                    fullWidth
                  >
                    <MenuItem key={0} value={0}>
                      Feed Automático
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      Breaking
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      Destaques
                    </MenuItem>
                    <MenuItem key={3} value={3}>
                      Fixos
                    </MenuItem>
                  </TextField>
                </Grid>
              }

              <Grid container spacing={1}>
                {feed.ids_pracas.map(praca => (
                  <Grid item xs={12} sm={6} key={"praca" + praca}>
                    <TextField
                      id={"praca" + praca}
                      select
                      label={"Comportamento de " + getPracaNameById(praca)}
                      value={feed.pracas_comportamentos.find(el => el.id_praca === praca).id_comportamento}
                      onChange={handleComportamentoChange(praca)}
                      margin="normal"
                      fullWidth
                    >
                      <MenuItem key={0} value={0}>
                        Feed Automático
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        Breaking
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        Destaques
                      </MenuItem>
                      <MenuItem key={3} value={3}>
                        Fixos
                      </MenuItem>
                    </TextField>
                  </Grid>
                ))}
              </Grid>
              {feed.pracas_comportamentos.filter(el => el.id_comportamento !== 0).length > 0 &&
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DatePickerComponent
                      onChange={value => setFeed({ ...feed, data_vigencia_inicio: value })}
                      value={feed.data_vigencia_inicio}
                      id="dataVigenciaInicio"
                      label="Data de Publicação"
                      disablePast={false}
                      disableFuture={false}
                      withTime
                      withoutMargins
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DatePickerComponent
                      onChange={value => setFeed({ ...feed, data_vigencia_fim: value })}
                      value={feed.data_vigencia_fim}
                      id="dataVigenciaFim"
                      label="Expirar em"
                      disablePast={false}
                      disableFuture={false}
                      withTime
                      withoutMargins
                    />
                  </Grid>
                </Grid>
              }
            </div>
          }
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>

          <Button onClick={handleCreateOrUpdate} color="primary">
            {actionTitle}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogLinksInternos 
        dialogOpen={dialogLinksInternosOpen}
        setDialogOpen={setDialogLinksInternosOpen}
        getSuggestion={suggestion => setFeed({ ...feed, url: suggestion.link })}
      />
    </>
  )
}

export default withStyles(styles)(DialogCapaComponent)