import React, { useState } from 'react'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid } from '@material-ui/core'
import ChipInputComponent from '../ChipInputComponent'
import DatePickerComponent from '../DatePickerComponent'
import { categorias } from '../../constants/selectOptions'

const DialogCapaSearchComponent = (props) => {
  const [chipInput, setChipInput] = useState('')

  const {
    dialogCapaSearchState,
    setDialogCapaSearchState,
    handleSearch,
    handleClose,
    isOpen,
  } = props

  const handleCheckboxChange = (id, nome) => event => {
    const { params } = dialogCapaSearchState
    let checkboxes = params[nome]

    if (event.target.checked) {
      checkboxes.push(id)
    } else {
      checkboxes.splice(checkboxes.indexOf(id), 1)
    }
    setDialogCapaSearchState({ ...dialogCapaSearchState, params: { ...dialogCapaSearchState.params, [nome]: checkboxes } })
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-capa"
    >
      <DialogTitle id="dialog-capa">
        Filtrar Conteúdos
      </DialogTitle>

      <DialogContent>
        <Grid container>
          {categorias.map(categoria => (
            <Grid item xs={12} sm={6} md={3} key={categoria.id}>
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={dialogCapaSearchState.params.ids_categorias.includes(categoria.id)}
                    onChange={handleCheckboxChange(categoria.id, 'ids_categorias')} 
                    value={categoria.id} 
                  />
                }
                label={categoria.nome}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <ChipInputComponent 
              value={chipInput}
              chips={dialogCapaSearchState.params.palavras_chave}
              onChipsChange={value => setDialogCapaSearchState({ ...dialogCapaSearchState, params: { ...dialogCapaSearchState.params, palavras_chave: value } })}
              onValueChange={value => setChipInput(value)}
              placeholder='Palavras-chave'
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6}>
            <DatePickerComponent
              onChange={value => setDialogCapaSearchState({ ...dialogCapaSearchState, params: { ...dialogCapaSearchState.params, data_inicio: value } })}
              value={dialogCapaSearchState.params.data_inicio}
              id="data_inicio"
              label="Data Início"
              disablePast={false}
              disableFuture={false}
              withoutMargins
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePickerComponent
              onChange={value => setDialogCapaSearchState({ ...dialogCapaSearchState, params: { ...dialogCapaSearchState.params, data_fim: value } })}
              value={dialogCapaSearchState.params.data_fim}
              id="data_fim"
              label="Data Fim"
              disablePast={false}
              disableFuture={false}
              withoutMargins
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>

        <Button onClick={handleSearch} color="primary">
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogCapaSearchComponent