import React, { useState } from 'react'
import { initialDialogSearchState } from '../../constants/initialConstants'
import DialogNotificacoesSearchComponent from '../../components/Notificacoes/DialogNotificacoesSearchComponent'

const DialogNotificacoesSearch = (props) => {
  const [dialogNotificacoesSearchState, setDialogNotificacoesSearchState] = useState(initialDialogSearchState)

  const { 
    isOpen,
    setIsOpen,
    searchNotificacoes
  } = props

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSearch = () => {
    searchNotificacoes(dialogNotificacoesSearchState)
    handleClose()
  }
  
  return (
    <DialogNotificacoesSearchComponent
      dialogNotificacoesSearchState={dialogNotificacoesSearchState}
      setDialogNotificacoesSearchState={setDialogNotificacoesSearchState}
      handleSearch={handleSearch}
      handleClose={handleClose}
      isOpen={isOpen}
    />
  )
}

export default DialogNotificacoesSearch