import React, { useEffect, useState, useContext } from 'react'
import { Fab, Grid, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { useDropzone } from 'react-dropzone'
import { SnackbarContext } from '../ContextProvider'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/dropzoneComponent'

const DropzoneComponent = props => {
  const { classes, onFileChange } = props
  const [files, setFiles] = useState([])
  const { showSnackbar } = useContext(SnackbarContext)

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDropAccepted: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
      onFileChange(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    },
    onDropRejected: () => {
      showSnackbar('O arquivo selecionado não é uma imagem válida!', 'error')
    }
  })
  
  const thumbs = files.map(file => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <Fab
          variant='round'
          size='small'
          color='secondary' 
          className={classes.removeFilesButton} 
          onClick={() => { setFiles([]); onFileChange('') }}>
          <ClearIcon />
        </Fab>
        <img
          src={file.preview}
          className={classes.img}
          alt='Imagem'
        />
      </div>
    </div>
  ))

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  return (
    <Grid container>
      <Grid item xs={12} sm={files.length > 0 ? 8 : 12} {...getRootProps({ className: 'dropzone ' + classes.dropzone })}>
        <input {...getInputProps()} />
        <Typography 
          variant='subtitle2' 
          style={{ fontWeight: 400, textAlign: 'center' }}>
          Arraste uma imagem ou clique aqui para selecioná-la
        </Typography>
      </Grid>
      <Grid item xs={12} sm={files.length > 0 ? 4 : false} className={classes.thumbsContainer}>
        {thumbs}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(DropzoneComponent)