import React, { useState, useEffect, useContext } from 'react'
import { initialDialogEstabelecimentosState } from '../../constants/initialConstants'
import { SnackbarContext } from '../../ContextProvider'
import { useRequest } from '../../hooks'

import DialogEstabelecimentosComponent from '../../components/Estabelecimentos/DialogEstabelecimentosComponent'

const DialogEstabelecimentos = (props) => {
  const { populateLookupField } = props
  const { showSnackbar } = useContext(SnackbarContext)

  const [estabelecimentoState, setEstabelecimentoState] = useState(
    initialDialogEstabelecimentosState
  )

  const {
    isOpen,
    setIsOpen,
    estabelecimentoId,
    setEstabelecimentoToUpdate,
    fetchEstabelecimentos,
  } = props

  const [
    estabelecimento,
    statusRequestEstabelecimento,
    fetchEstabelecimento,
  ] = useRequest(
    [],
    {
      url: `estabelecimentos/${estabelecimentoId}`,
      method: 'get',
    },
    null
  )

  const [
    categoriasEstabelecimento,
    statusRequestCategoriasEstabelecimento,
    fetchCategoriasEstabelecimento,
  ] = useRequest(
    [],
    {
      url: `estabelecimentos/categorias/`,
      method: 'get',
    },
    null
  )

  const [, , update] = useRequest(
    {},
    {
      url: 'estabelecimentos',
      method: 'put',
      data: estabelecimentoState,
    },
    null,
    {
      onComplete: (response) => {
        handleClose()
        fetchEstabelecimentos()
        showSnackbar('Estabelecimento editado com sucesso!', 'success')
      },
    }
  )

  useEffect(() => {
    if (estabelecimentoId) {
      fetchCategoriasEstabelecimento()
      fetchEstabelecimento()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estabelecimentoId])

  useEffect(() => {
    if (estabelecimento.id) {
      setEstabelecimentoState({ ...estabelecimento })
    }
  }, [estabelecimento])

  const handleUpdate = () => {
    update()
  }

  const handleClose = () => {
    if (setEstabelecimentoToUpdate) {
      setEstabelecimentoToUpdate({})
    }

    setEstabelecimentoState(initialDialogEstabelecimentosState)
    setIsOpen(false)
  }

  return (
    <DialogEstabelecimentosComponent
      handleCreateOrUpdate={handleUpdate}
      handleClose={handleClose}
      isOpen={isOpen}
      estabelecimento={estabelecimentoState}
      setEstabelecimento={setEstabelecimentoState}
      statusRequest={
        statusRequestEstabelecimento.isLoading ||
        statusRequestCategoriasEstabelecimento.isLoading
      }
      populateLookupField={populateLookupField}
      categoriasEstabelecimento={categoriasEstabelecimento}
    />
  )
}

export default DialogEstabelecimentos
