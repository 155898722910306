import React, { useState, useEffect, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import Pagination from "material-ui-flat-pagination"

import HeaderActions from '../../components/HeaderActions'
import CardsNotificacoes from '../../components/Notificacoes/CardsNotificacoes'

import { useRequest } from '../../hooks'

import api from "../../services/api"

import DialogNotificacoes from './DialogNotificacoes'
import DialogNotificacoesDelete from './DialogNotificacoesDelete'
import DialogNotificacoesSearch from './DialogNotificacoesSearch'

const PAGESIZE = 30;

const Notificacoes = (props) => {
  const { populateLookupField } = props
  const [pagination, setPagination] = useState({
    currentPage: 0,
    pageSize: PAGESIZE,
    offsetPage: 0 })

  const [praca, setPraca] = useState(9)
  const [searchParams, setSearchParams] = useState(false)

  const [isDialogOpen,       setIsDialogOpen] =       useState(false)
  const [isDialogDeleteOpen, setIsDialogDeleteOpen] = useState(false)
  const [isDialogSearchOpen, setIsDialogSearchOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [notificacaoToUpdate, setNotificacaoToUpdate] = useState({})
  const [notificacaoToDelete, setNotificacaoToDelete] = useState({})
  const [notificacaoToCopy, setNotificacaoToCopy] = useState({})

  const [notifications, setNotifications] = useState([])

  const fetchNotificacoes = useCallback(async() => {
    setIsLoading(true)
    try {
      const response = await api.get('notificacao/listar', {params: searchParams ? { ...searchParams, ...pagination } : pagination})
      setNotifications(prev => prev.concat(response.data))
    }catch(error){
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  },[pagination])


  useEffect(() => {
    fetchNotificacoes()
  }, [fetchNotificacoes])

  useEffect(() => {
    if (Object.keys(notificacaoToUpdate).length) {
      setIsDialogOpen('edit')
    }
  }, [notificacaoToUpdate])

  useEffect(() => {
    if (Object.keys(notificacaoToDelete).length) {
      setIsDialogDeleteOpen(true)
    }
  }, [notificacaoToDelete])

  useEffect(() => {
    if (Object.keys(notificacaoToCopy).length) {
      setIsDialogOpen('copy')
    }
  }, [notificacaoToCopy])

  useEffect(() => {
    setPagination({ currentPage: 0, pageSize: PAGESIZE, offsetPage: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const searchNotificacoes = (pesquisa) => {
    setSearchParams(pesquisa)
  }

  const clearPesquisa = () => {
    setSearchParams(false)
  }

  const fetchMoreData = useCallback(() => {
    // const fetchMoreData = useCallback(() => {
    //   setPagination((prevState) => ({
    //     currentPage: prevState.currentPage + 1,
    //     pageSize: PAGESIZE,
    //     offsetPage: (fotosEVideos.length * (fotosEVideos.length / PAGESIZE))
    //   }))
    // },[fotosEVideos])
    setPagination((prevState) => ({
      currentPage: prevState.currentPage + 1,
      pageSize: PAGESIZE,
      offsetPage: (notifications.length * (notifications.length / PAGESIZE))
    }))
  },[notifications])

  return (
    <Grid container style={{maxWidth: 1280, margin: '0 auto'}}>
      <Grid item xs={12}>
        <HeaderActions
          setIsDialogOpen={setIsDialogOpen}
          setIsDialogSearchOpen={setIsDialogSearchOpen}
          buttonActionName='Nova Notificação'
          praca={praca}
          setPraca={setPraca}
          search={searchNotificacoes}
          searchParams={searchParams}
          clearPesquisa={clearPesquisa}
          statusRequest={isLoading}
          populateLookupField={populateLookupField}
        />
        <br />
        <CardsNotificacoes
          notificacoes={notifications.length ? notifications : []}
          setNotificacaoToUpdate={setNotificacaoToUpdate}
          setNotificacaoToDelete={setNotificacaoToDelete}
          setNotificacaoToCopy={setNotificacaoToCopy}
          populateLookupField={populateLookupField}
          fetchMoreData={fetchMoreData}
        />
      </Grid>
      
      {/* <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
          <Pagination
            limit={pagination.pageSize}
            offset={pagination.currentPage * pagination.pageSize}
            total={notificacoes.count ? notificacoes.count : 0}
            onClick={(e, offset) => handlePaginationClick(offset)}
            size='large'
          />
        </div>
      </Grid> */}

      <DialogNotificacoes
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        notificacaoId={notificacaoToUpdate.id ? notificacaoToUpdate.id : notificacaoToCopy.id ? notificacaoToCopy.id : ''}
        setNotificacaoToUpdate={setNotificacaoToUpdate}
        notificacaoToCopy={notificacaoToCopy}
        setNotificacaoToCopy={setNotificacaoToCopy}
        fetchNotificacoes={fetchNotificacoes}
        populateLookupField={populateLookupField}
      />

      <DialogNotificacoesDelete
        isOpen={isDialogDeleteOpen}
        setIsOpen={setIsDialogDeleteOpen}
        notificacaoId={notificacaoToDelete.id}
        setNotificacaoToDelete={setNotificacaoToDelete}
        fetchNotificacoes={fetchNotificacoes}
      />

      <DialogNotificacoesSearch
        isOpen={isDialogSearchOpen}
        setIsOpen={setIsDialogSearchOpen}
        searchNotificacoes={searchNotificacoes}
      />
    </Grid>
  )
}

export default Notificacoes