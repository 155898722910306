import React from 'react'
import moment from 'moment'
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../../styles/js/cardsNotificacoes'

import {
  getTagCategoriaColor,
  getStatusAgendamentoColor,
} from '../../helpers/capa'

const CardsNotificacoes = props => {
  const {
    classes,
    notificacoes,
    setNotificacaoToUpdate,
    setNotificacaoToDelete,
    setNotificacaoToCopy,
    populateLookupField,
    fetchMoreData
  } = props

  return (

      <InfiniteScroll
          dataLength={notificacoes.length}
          next={fetchMoreData}
          hasMore={true}
          loader={<h4>Carregando...</h4>}
        >
               <Grid container spacing={2}>
           {notificacoes.map(notificacao => {
        let tagCategoriaColor = getTagCategoriaColor(notificacao.id_categoria)
   
        return (
          <Grid item xs={12} md={6} key={notificacao.id}>
            <Card
              className={classes.card}
              onClick={() =>
                populateLookupField ? populateLookupField(notificacao) : {}
              }
            >
              <CardContent>
                <div
                  className={classes.cardTag}
                  style={{ backgroundColor: tagCategoriaColor }}
                >
                  {notificacao.categoria.nome}
                </div>

                <Typography variant='h6' className={classes.cardTitulo}>
                  {notificacao.titulo}
                </Typography>

                <Typography paragraph className={classes.cardMensagem}>
                  {notificacao.mensagem}
                </Typography>

                <Typography variant='caption' className={classes.cardUrl}>
                  {notificacao.url}
                </Typography>

                <Grid container>
                  <Grid item xs={6} sm={7} md={8} lg={9}>
                    <Typography paragraph className={classes.cardData}>
                      <span
                        style={{
                          fontWeight: 500,
                          color: getStatusAgendamentoColor(notificacao),
                        }}
                      >
                        {notificacao.status_agendamento_notificacao.nome}
                      </span>
                      :{' '}
                      <span style={{ fontWeight: 500 }}>
                        {moment(notificacao.data_hora).calendar()}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={classes.cardActions}>
                <Tooltip title='Copiar'>
                  <IconButton onClick={() => setNotificacaoToCopy(notificacao)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Excluir'>
                  <IconButton
                    onClick={() => setNotificacaoToDelete(notificacao)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>

                {!populateLookupField && (
                  <Tooltip title='Alterar'>
                    <IconButton
                      onClick={() => setNotificacaoToUpdate(notificacao)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </CardActions>
            </Card>
          </Grid>
        )
      })}
      </Grid>
        </InfiniteScroll>

  )
}

export default withStyles(styles)(CardsNotificacoes)
