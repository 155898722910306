import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core'

const DialogCategoriasComponent = (props) => {
  const { 
    isOpen,
    handleClose,
    categoria,
    setCategoria,
    action,
    title,
    actionTitle,
    // handleCheckboxChange
  } = props

  const handleAction = () => {
    action()
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-categorias"
    >
      <DialogTitle id="dialog-categorias">
        {title}
      </DialogTitle>

      <DialogContent>
        {/* <Grid container>
          {pracas.map(praca => (
            <Grid item xs={12} sm={6} md={4} key={praca.id}>
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={categoria.ids_pracas ? categoria.ids_pracas.includes(praca.id) : false}
                    onChange={handleCheckboxChange(praca.id, 'ids_pracas')} 
                    value={praca.id} 
                  />
                }
                label={praca.nome}
              />
            </Grid>
          ))}
        </Grid>
        <br /> */}
        <Grid container>
          <Grid item xs={12}>
            <TextField 
              id='categoria'
              value={categoria.nome || ''}
              onChange={event => setCategoria({ ...categoria, nome: event.target.value })}
              label='Categoria'
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color='primary' onClick={handleAction}>
          {actionTitle}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogCategoriasComponent