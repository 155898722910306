export const styles = theme => ({
  card: {
    position: 'relative',
    // backgroundColor: '#fff',
    height: '100%',
    border: '1px solid #ddd',
    borderRadius: 0,
    boxShadow: 'none',
  },

  cardContent: {
    position: 'relative',
  },
  
  cardTag: {
    width: 75,
    color: 'white',
    padding: '2px 4px',
    textAlign: 'center',
    fontSize: '11pt',
    fontWeight: 500,
    borderRadius: 5,
    display: 'inline-block',
    marginRight: 15,
  },

  cardTitulo: {
    fontSize: '14pt',
    display: 'inline',
  },

  cardMensagem: {
    color: '#555',
  },

  cardUrl: {
    color: '#888',
  },

  cardData: {
    color: '#555',
    marginTop: 16,
    marginBottom: 0,
  },

  cardActions: {
    position: 'absolute',
    bottom: 0,
    right: 5,
  },

  comportamentoTitle: {
    margin: '16px 0px',
  },
})