import React, { useState, useEffect, useContext } from 'react'

import { useRequest } from '../../hooks'
import { SnackbarContext } from '../../ContextProvider'

import { initialDialogDeleteState } from '../../constants/initialConstants'
import DialogNotificacoesDeleteComponent from '../../components/Notificacoes/DialogNotificacoesDeleteComponent'

const DialogNotificacoesDelete = (props) => {
  const { showSnackbar } = useContext(SnackbarContext)
  const [dialogNotificacoesDeleteState, setDialogNotificacoesDeleteState] = useState(initialDialogDeleteState)

  const { 
    isOpen,
    setIsOpen,
    notificacaoId,
    setNotificacaoToDelete,
    fetchNotificacoes,
  } = props

  const [notificacao, statusRequestNotificacao, fetchNotificacao] = useRequest(
    [],
    {
      url: `notificacao/${notificacaoId}`,
      method: 'get',
    },
    null
  )

  const [, , deleteNotificacao] = useRequest(
    {},
    {
      url: 'notificacao',
      method: 'delete',
      data: dialogNotificacoesDeleteState
    },
    null,
    {
      onComplete: response => {
        if (typeof response === 'string') {
          fetchNotificacoes()
          handleClose()
          showSnackbar('Conteúdo deletado com sucesso nas praças selecionadas!', 'success')
        } else {
          showSnackbar('Por favor, ' + response[0], 'error')
        }
      }
    }
  )

  useEffect(() => {
    if (notificacaoId) {
      fetchNotificacao()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificacaoId])

  useEffect(() => {
    if (notificacao.id) {
      setDialogNotificacoesDeleteState({ 
        id_notificacao: notificacao.id,
      })
    }
  }, [notificacao])

  useEffect(() => {
    if (isOpen) {
      setDialogNotificacoesDeleteState({ ...dialogNotificacoesDeleteState, id_notificacao: notificacao.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleClose = () => {
    setNotificacaoToDelete({})
    setDialogNotificacoesDeleteState({ ids_pracas: [], id_notificacao: null })
    setIsOpen(false)
  }

  const handleDelete = () => {
    deleteNotificacao()
    handleClose()
  }

  return (
    <DialogNotificacoesDeleteComponent
      handleDelete={handleDelete}
      notificacao={notificacao}
      isOpen={isOpen}
      handleClose={handleClose}
      dialogNotificacoesDeleteState={dialogNotificacoesDeleteState}
      setDialogNotificacoesDeleteState={setDialogNotificacoesDeleteState}
      statusRequestNotificacao={statusRequestNotificacao}
    />
  )
}

export default DialogNotificacoesDelete