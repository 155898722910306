import React, { useState, useEffect, useContext } from 'react'
import { Grid } from '@material-ui/core'
import Pagination from 'material-ui-flat-pagination'

import HeaderActions from '../../components/HeaderActions'
import TableEstabelecimentos from '../../components/Estabelecimentos/TableEstabelecimentos'
import { SnackbarContext } from '../../ContextProvider'

import { useRequest } from '../../hooks'

import DialogEstabelecimentos from '../Estabelecimentos/DialogEstabelecimentos'
// import DialogEstabelecimentosDelete from '../Estabelecimentos/DialogEstabelecimentosDelete'
import DialogEstabelecimentosSearch from '../Estabelecimentos/DialogEstabelecimentosSearch'
import { estabelecimentos as estabelecimentosState } from '../../constants/selectOptions'

const Estabelecimentos = (props) => {
  const { populateLookupField } = props
  const { showSnackbar } = useContext(SnackbarContext)
  const [pagination, setPagination] = useState({ currentPage: 0, pageSize: 20 })
  const [searchParams, setSearchParams] = useState(false)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isDialogSearchOpen, setIsDialogSearchOpen] = useState(false)

  const [estabelecimentoToUpdate, setEstabelecimentoToUpdate] = useState({})
  const [estabelecimentoToDelete, setEstabelecimentoToDelete] = useState({})

  const [
    estabelecimentos,
    statusRequestEstabelecimentos,
    fetchEstabelecimentos,
  ] = useRequest(
    [],
    {
      url: 'estabelecimentos',
      method: 'get',
      params: searchParams ? { order_by: 'nome', ...searchParams, ...pagination } : { order_by: 'nome', ...pagination },
    },
    null
  )

  const [
    ,
    ,
    deleteEstabelecimento,
  ] = useRequest(
    [],
    {
      url: 'estabelecimentos',
      method: 'delete',
      data: {
        id: estabelecimentoToDelete.id
      },
    },
    null,
    {
      onComplete: data => {
        fetchEstabelecimentos()
        if (data.status === 200) {
          showSnackbar('Estabelecimento removido com sucesso!', 'success')
        }
      }
    }
  )

  useEffect(() => {
    fetchEstabelecimentos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  useEffect(() => {
    if (Object.keys(estabelecimentoToUpdate).length) {
      setIsDialogOpen('edit')
    }
  }, [estabelecimentoToUpdate])

  useEffect(() => {
    if (Object.keys(estabelecimentoToDelete).length) {
      const confirmDelete = window.confirm(`Deseja remover o estabelecimento "${estabelecimentoToDelete.nome}"?`)
      
      if (confirmDelete) {
        deleteEstabelecimento()
      }
    }
  }, [estabelecimentoToDelete])

  useEffect(() => {
    setPagination({ ...pagination, currentPage: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const searchEstabelecimentos = (pesquisa) => {
    setSearchParams(pesquisa)
  }

  const clearPesquisa = () => {
    setSearchParams(false)
  }

  const handlePaginationClick = (offset) => {
    setPagination({ ...pagination, currentPage: offset / pagination.pageSize })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderActions
          search={searchEstabelecimentos}
          searchParams={searchParams}
          clearPesquisa={clearPesquisa}
          statusRequest={!statusRequestEstabelecimentos.done}
          populateLookupField={populateLookupField}
          setIsDialogSearchOpen={setIsDialogSearchOpen}
        />
        <br />
        <TableEstabelecimentos
          rows={estabelecimentos.rows ? estabelecimentos.rows : []}
          setEstabelecimentoToUpdate={setEstabelecimentoToUpdate}
          setEstabelecimentoToDelete={setEstabelecimentoToDelete}
          populateLookupField={populateLookupField}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={estabelecimentos.count}
        />
      </Grid>

      <DialogEstabelecimentos
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        estabelecimentoId={
          estabelecimentoToUpdate.id ? estabelecimentoToUpdate.id : ''
        }
        setEstabelecimentoToUpdate={setEstabelecimentoToUpdate}
        fetchEstabelecimentos={fetchEstabelecimentos}
        populateLookupField={populateLookupField}
      />

      <DialogEstabelecimentosSearch
        isOpen={isDialogSearchOpen}
        setIsOpen={setIsDialogSearchOpen}
        searchEstabelecimentos={searchEstabelecimentos}
      />
    </Grid>
  )
}

export default Estabelecimentos
