import React, { useState, useEffect } from 'react'
import { useRequest } from '../../hooks'
import { initialDialogSearchState } from '../../constants/initialConstants'
import DialogFotosEVideosSearchComponent from '../../components/FotosEVideos/DialogFotosEVideosSearchComponent'

const DialogFotosEVideosSearch = props => {
  const [
    dialogFotosEVideosSearchState,
    setDialogFotosEVideosSearchState,
  ] = useState(initialDialogSearchState)

  const { isOpen, setIsOpen, searchFotosEVideos } = props

  const [categoriasEnvio, , fetchCategoriasEnvio] = useRequest(
    [],
    {
      url: `categoria_envio/listar`,
      method: 'get',
      params: { currentPage: 0, pageSize: 10000 },
    },
    null
  )

  useEffect(() => {
    if (isOpen) {
      fetchCategoriasEnvio()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSearch = () => {
    searchFotosEVideos(dialogFotosEVideosSearchState)
    handleClose()
  }

  return (
    <DialogFotosEVideosSearchComponent
      dialogFotosEVideosSearchState={dialogFotosEVideosSearchState}
      setDialogFotosEVideosSearchState={setDialogFotosEVideosSearchState}
      handleSearch={handleSearch}
      handleClose={handleClose}
      isOpen={isOpen}
      categoriasEnvio={categoriasEnvio.rows ? categoriasEnvio.rows : []}
    />
  )
}

export default DialogFotosEVideosSearch
