export const styles = theme => ({
  root: {
    height: "calc(100vh - 100px)"
  },

  httpIconCircle: {
    height: 250,
    width: "100%",
    maxWidth: 250,
    backgroundColor: "#f3f3f3",
    borderRadius: "100%",
    margin: "0 auto"
  },

  httpIconTitle: {
    textAlign: "center",
    color: "#ccc",
    fontWeight: 600
  },

  httpIconSubtitle: {
    textAlign: "center",
    color: "#bbb",
    fontWeight: 400
  },

  httpMessageTitle: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#cb5141",
    marginBottom: 20
  },

  httpMessageText: {
    textAlign: "center",
    color: "#777",
    fontWeight: 400,
    fontSize: 18,
    marginBottom: 30
  },

  httpMessageLink: {
    textAlign: "center",
    textDecoration: "none",
    color: "grey",
    "&:hover": {
      color: "#0075bc"
    }
  }
});
