import React, { Fragment, useContext } from 'react'
import { Switch, BrowserRouter, Route, withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import Navbar from '../components/Navbar.jsx'
import HttpRequestHandler from '../components/HttpRequestHandler.jsx'

import Capa from '../containers/Capa/Capa.jsx'
import Notificacoes from '../containers/Notificacoes/Notificacoes.jsx'
import FotosEVideos from '../containers/FotosEVideos/FotosEVideos.jsx'
import Estabelecimentos from '../containers/Estabelecimentos/Estabelecimentos.jsx'
import { ApplicationContext } from '../ContextProvider.jsx'

import * as usuarios from '../constants/usuarios'
import '../styles/css/style.css'

const ApplicationRouter = () => {
  const { user } = useContext(ApplicationContext)
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={withNavbar(Capa, 'Capa', user)} />
        <Route
          exact
          path='/notificacoes'
          component={withNavbar(Notificacoes, 'Notificações', user)}
        />
        <Route
          exact
          path='/fotos-e-videos'
          component={withNavbar(FotosEVideos, 'Fotos e Vídeos', user)}
        />
        <Route
          exact
          path='/estabelecimentos'
          component={withNavbar(Estabelecimentos, 'Estabelecimentos', user)}
        />
        <Route
          component={withNavbar(
            HttpRequestHandler,
            'Página não encontrada',
            user
          )}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default ApplicationRouter

// HOC to wrap component with Navbar
const withNavbar = (WrappedComponent, appBarTitle, user) => {
  const styles = (theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
  })

  class withNavbar extends React.Component {
    renderWrappedComponent = () => {
      if (appBarTitle === 'Página não encontrada') {
        return (
          <WrappedComponent
            errorCode='404'
            errorTitle={appBarTitle}
            errorDescription='O link que você tentou acessar não pode ser encontrado. Tem certeza que você o digitou corretamente?'
            redirectLink='/'
            redirectLinkText='Clique aqui para voltar à Capa'
          />
        )
      }

      return <WrappedComponent />
    }

    render() {
      if (
        this.props.location.pathname !== '/fotos-e-videos' &&
        user.tipo === usuarios.JORNALISTA
      ) {
        this.props.history.push('/fotos-e-videos')
      }

      return (
        <Fragment>
          <Navbar appBarTitle={appBarTitle} />
          <main className={this.props.classes.content}>
            <div style={{ minHeight: 35 }} />
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                {this.renderWrappedComponent()}
              </Grid>
            </Grid>
          </main>
        </Fragment>
      )
    }
  }

  withNavbar.displayName = `withNavbar(${getDisplayName(WrappedComponent)})`
  return withRouter(withStyles(styles)(withNavbar))
}

const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
