import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import Pagination from 'material-ui-flat-pagination'

import { useRequest } from '../../hooks'

import HeaderActions from '../../components/HeaderActions'
import CardsFotosEVideos from '../../components/FotosEVideos/CardsFotosEVideos'
import DialogFotosEVideosSearch from './DialogFotosEVideosSearch'
import DialogFotosEVideosDetails from '../../components/Dialogs/DialogFotosEVideosDetails'
import LookupCategorias from '../Lookups/LookupCategorias'
import { ApplicationContext } from '../../ContextProvider.jsx'
import * as usuarios from '../../constants/usuarios'
import api from '../../services/api'

const PAGESIZE = 24;

const FotosEVideos = props => {
  const { user } = useContext(ApplicationContext)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: PAGESIZE,
    offsetPage: 0,
  })
  const [praca, setPraca] = useState('todos')
  const [searchParams, setSearchParams] = useState(false)

  const [isDialogOpen, setIsDialogOpen] = useState({
    isOpen: false,
    data: null,
  })
  const [isDialogSearchOpen, setIsDialogSearchOpen] = useState(false)
  const [isLookupCategoriaOpen, setIsLookupCategoriaOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [fotosEVideos, setFotosEVideos] = useState([])
  const [arquivoState, setArquivoState] = useState({})

  const [fotoEVideoToBeOpened, setFotoEVideoToBeOpened] = useState('')

  const fetchFotosEVideos = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await api.get('midias_recebidas/listar', {params: searchParams
              ? { ...searchParams, ...pagination, praca: praca }
              : { ...pagination, praca: praca }})
      setFotosEVideos(prev => prev.concat(response.data))
    }catch(error){
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  },[pagination,searchParams])

  const [, statusRequestFotoEVideo, fetchFotoEVideo] = useRequest(
    [],
    {
      url: `midias_recebidas/visualizar/${fotoEVideoToBeOpened}`,
      method: 'get',
    },
    null,
    {
      onComplete: data => {
        setIsDialogOpen({ isOpen: true, data: data })
      },
    }
  )

  const [, statusRequestUpdateArquivo, updateArquivo] = useRequest(
    {},
    {
      url: 'midias_recebidas/atualizar',
      method: 'post',
      data: arquivoState,
    },
    null,
    {
      onComplete: data => {
        fotosEVideos
          .find(fotoEVideo => fotoEVideo.id === data.id_envio)
          .arquivos.find(arquivo => arquivo.id === data.id).publicado =
          data.publicado
        setArquivoState({})
      },
    }
  )

  useEffect(() => {
    if (arquivoState.id) {
      updateArquivo()
    }
  }, [arquivoState])

  // useEffect(() => {
  //   setPagination({ currentPage: 1, pageSize: PAGESIZE, offsetPage: 0 })
  //   setFotosEVideos([])
  // }, [praca])

  useEffect(() => {
    fetchFotosEVideos()
  }, [fetchFotosEVideos])

  useEffect(() => {
    if (fotoEVideoToBeOpened) {
      fetchFotoEVideo()
    }
  }, [fotoEVideoToBeOpened])

  useEffect(() => {
    // setPagination({ currentPage: 1, pageSize: PAGESIZE, offsetPage: 0 })
  }, [searchParams])
  
  const searchFotosEVideos = pesquisa => {
    setSearchParams(pesquisa)
    // setFotosEVideos([])
  }

  const clearPesquisa = () => {
    setSearchParams(false)
  }

  const handleDownload = () => {}

  // const handlePaginationClick = offset => {
  //   setPagination({ ...pagination, currentPage: offset / pagination.pageSize })
  // }

  const fetchMoreData = useCallback(() => {
    setPagination((prevState) => ({
      currentPage: prevState.currentPage + 1,
      pageSize: PAGESIZE,
      offsetPage: (fotosEVideos.length * (fotosEVideos.length / PAGESIZE))
    }))
  },[fotosEVideos])


  const handleChangePraca = (praca) => {
    setPagination({ currentPage: 1, pageSize: PAGESIZE, offsetPage: 0 })
    setPraca(praca)
    setFotosEVideos([])
  }

  return (
    <Grid container style={{maxWidth: 1280, margin: '0 auto'}}>
      <Grid item xs={12}>
        <HeaderActions
          praca={praca}
          setPraca={handleChangePraca}
          setIsDialogOpen={setIsLookupCategoriaOpen}
          setIsDialogSearchOpen={setIsDialogSearchOpen}
          buttonActionName={
            user.tipo === usuarios.JORNALISTA
              ? ''
              : 'Gerenciar Categorias de Envio'
          }
          search={searchFotosEVideos}
          searchParams={searchParams}
          clearPesquisa={clearPesquisa}
          statusRequest={isLoading}
          withPracas
          withAll
        />
        <br />

        <CardsFotosEVideos
          fotosEVideos={fotosEVideos.length > 0 ? fotosEVideos : []}
          setFotoEVideoToBeOpened={setFotoEVideoToBeOpened}
          download={handleDownload}
          fetchMoreData={fetchMoreData}
        />
      </Grid>

      {/* <Grid item xs={12}>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}
        >
          <Pagination
            limit={pagination.pageSize}
            offset={pagination.currentPage * pagination.pageSize}
            total={fotosEVideos.count ? fotosEVideos.count : 0}
            onClick={(e, offset) => handlePaginationClick(offset)}
            size='large'
          />
        </div>
      </Grid> */}

      <DialogFotosEVideosDetails
        dialog={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        fotoEVideo={fotoEVideoToBeOpened}
        setFotoEVideoToBeOpened={setFotoEVideoToBeOpened}
        download={handleDownload}
        setArquivoState={setArquivoState}
        statusRequestUpdateArquivo={statusRequestUpdateArquivo}
        statusRequestFotoEVideo={statusRequestFotoEVideo}
      />

      <DialogFotosEVideosSearch
        isOpen={isDialogSearchOpen}
        setIsOpen={setIsDialogSearchOpen}
        searchFotosEVideos={searchFotosEVideos}
      />

      <LookupCategorias
        isOpen={isLookupCategoriaOpen}
        handleClose={() => setIsLookupCategoriaOpen(false)}
      />
    </Grid>
  )
}

export default FotosEVideos
