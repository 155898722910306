import g1 from '../assets/images/g1.png'
import ge from '../assets/images/ge.png'
import gshow from '../assets/images/gshow.png'
import rpc from '../assets/images/rpc.jpg'

import moment from 'moment'
import 'moment/locale/pt-br'

export const getExpirationDate = value => {
  const feedPraca = value.feeds_praca[0]

  if (feedPraca.feed_praca_comportamento_feed.length > 0) {
    const feedPracaComportamentoFeed = feedPraca.feed_praca_comportamento_feed[0]

    if (feedPracaComportamentoFeed.data_vigencia_fim) {
      return `Expira ${moment(feedPracaComportamentoFeed.data_vigencia_fim).calendar().toLowerCase()}`
    }
    return 'Expiração indeterminada'
  }
  return ''
}

export const getTagCategoriaColor = value => {
  if (value === 1) {
    return '#A6130A'
  } else if (value === 2) {
    return '#3B9B01'
  } else if (value === 3) {
    return '#EC7D01'
  } else if (value === 4) {
    return '#006497'
  } else {
    return '#AAAAAA'
  }
}

export const getTagTipoColor = value => {
  if (value === 1) {
    return '#26C1C3'
  } else if (value === 2) {
    return '#0C82BE'
  } else {
    return '#AAAAAA'
  }
}

export const getTagEspecialPublicitario = (url) => {
  if (url.includes('/especial-publicitario/')) {
    return true
  }
  return false
}

export const renderCardImage = value => {
  if (value.imagem_alt) return value.imagem_alt
  if (value.imagem) return value.imagem
  if (value.id_categoria === 1) return g1
  if (value.id_categoria === 2) return ge
  if (value.id_categoria === 3) return gshow
  if (value.id_categoria === 4) return rpc
}

export const getPracaNameById = idPraca => {
  if (idPraca === 1) return 'Cascavel'
  if (idPraca === 2) return 'Curitiba'
  if (idPraca === 3) return 'Foz do Iguaçu'
  if (idPraca === 4) return 'Guarapuava'
  if (idPraca === 5) return 'Londrina'
  if (idPraca === 6) return 'Maringá'
  if (idPraca === 7) return 'Noroeste'
  if (idPraca === 8) return 'Ponta Grossa'
  if (idPraca === 9) return 'Paraná'
}

export const getStatusAgendamentoColor = value => {
  let color = ''

  switch (value.status_agendamento_notificacao.nome) {
    case 'Agendado':
      color = '#d68f20'
      break
    case 'Cancelado':
      color = '#cf4638'
      break
    case 'Enviado':
      color = '#33a59e'
      break
    case 'Falha':
      color = '#cf4638'
      break
    default:
      return ''
  }

  return color
}