import React, { useState, useEffect } from 'react'
import { remove } from 'lodash'
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../../styles/js/dialog'

const DialogEstabelecimentosComponent = (props) => {
  const {
    classes,
    handleCreateOrUpdate,
    handleClose,
    isOpen,
    estabelecimento,
    setEstabelecimento,
    categoriasEstabelecimento,
    statusRequest,
  } = props

  const handleChange = (name, accessor, idAccessor) => (event) => {
    if (accessor) {
      const updatedEstabelecimento = estabelecimento[accessor].find(
        (endereco) => endereco.id === idAccessor
      )

      if (
        idAccessor.toString().substring(0, 3) !== 'new' &&
        !updatedEstabelecimento.updated
      ) {
        updatedEstabelecimento.updated = true
      }

      updatedEstabelecimento[name] = event.target.value

      setEstabelecimento({
        ...estabelecimento,
      })
    } else {
      setEstabelecimento({ ...estabelecimento, [name]: event.target.value })
    }
  }

  const addEndereco = () => {
    estabelecimento.enderecos.push({
      id: `new-${new Date()}`,
      id_estabelecimento: estabelecimento.id,
      telefone: '',
      bairro: '',
      cidade: '',
      endereco: null,
      new: true,
      ativo: true,
    })

    setEstabelecimento({ ...estabelecimento })
  }

  const removeEndereco = (idEndereco) => {
    const deletedEndereco = estabelecimento.enderecos.find(
      (endereco) => endereco.id === idEndereco
    )
    if (deletedEndereco.new) {
      remove(estabelecimento.enderecos, { id: idEndereco })
    } else {
      deletedEndereco.new = false
      deletedEndereco.updated = true
      deletedEndereco.deleted = true
      deletedEndereco.ativo = false
    }

    setEstabelecimento({ ...estabelecimento })
  }

  // ativo: '',

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={!!isOpen}
        onClose={handleClose}
        aria-labelledby='dialog-estabelecimentos'
      >
        <DialogTitle id='dialog-estabelecimentos'>
          {`Atualizar Estabelecimento ${
            estabelecimento.id ? `| ${estabelecimento.id}` : ''
          }`}
        </DialogTitle>

        {statusRequest && (
          <div style={{ position: 'relative' }}>
            <LinearProgress
              style={{
                position: 'absolute',
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          </div>
        )}

        {(estabelecimento.id || isOpen === 'create') && (
          <DialogContent>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={estabelecimento.ativo}
                      onChange={(event) =>
                        setEstabelecimento({
                          ...estabelecimento,
                          ativo: event.target.checked,
                        })
                      }
                      value='ativo'
                      color='primary'
                    />
                  }
                  label='Ativo?'
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={estabelecimento.negocio}
                      onChange={(event) =>
                        setEstabelecimento({
                          ...estabelecimento,
                          negocio: event.target.checked,
                        })
                      }
                      value='negocio'
                      color='primary'
                    />
                  }
                  label='Negócio?'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='nome'
                  label='Nome'
                  value={estabelecimento.nome || ''}
                  onChange={handleChange('nome')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='nome_fantasia'
                  label='Nome Fantasia'
                  value={estabelecimento.nome_fantasia || ''}
                  onChange={handleChange('nome_fantasia')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='cnpj'
                  label='CNPJ'
                  value={estabelecimento.cnpj || ''}
                  onChange={handleChange('cnpj')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='email'
                  label='E-mail'
                  value={estabelecimento.email || ''}
                  onChange={handleChange('email')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='whatsapp'
                  label='Whatsapp'
                  value={estabelecimento.whatsapp || ''}
                  onChange={handleChange('whatsapp')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='instagram'
                  label='Instagram'
                  value={estabelecimento.instagram || ''}
                  onChange={handleChange('instagram')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='facebook'
                  label='Facebook'
                  value={estabelecimento.facebook || ''}
                  onChange={handleChange('facebook')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='site'
                  label='Website'
                  value={estabelecimento.site || ''}
                  onChange={handleChange('site')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  select
                  id='status'
                  label='Status do Estabelecimento'
                  value={estabelecimento.status || ''}
                  onChange={handleChange('status')}
                  margin='normal'
                  fullWidth
                >
                  <MenuItem key={0} value={'null'}>
                    Vazio (sem status)
                  </MenuItem>
                  <MenuItem key={0} value={1}>
                    Cadastro rejeitado
                  </MenuItem>
                  <MenuItem key={1} value={2}>
                    Não autorizado em decreto
                  </MenuItem>
                  <MenuItem key={2} value={3}>
                    Contactado
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  select
                  id='id_categoria_estabelecimento'
                  label='Categoria do Estabelecimento'
                  value={estabelecimento.id_categoria_estabelecimento || ''}
                  onChange={handleChange('id_categoria_estabelecimento')}
                  margin='normal'
                  fullWidth
                >
                  {categoriasEstabelecimento.map((categoria) => (
                    <MenuItem key={categoria.id} value={categoria.id}>
                      {categoria.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  select
                  id='atendendo_como'
                  label='Atendendo como'
                  value={estabelecimento.atendendo_como || ''}
                  onChange={handleChange('atendendo_como')}
                  margin='normal'
                  fullWidth
                >
                  <MenuItem key={0} value={1}>
                    Delivery
                  </MenuItem>
                  <MenuItem key={1} value={2}>
                    Retirada
                  </MenuItem>
                  <MenuItem key={2} value={3}>
                    Delivery e Retirada
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='o_que_vende'
                  label='O que vende'
                  value={estabelecimento.o_que_vende || ''}
                  onChange={handleChange('o_que_vende')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              {estabelecimento.enderecos.map(
                (endereco) =>
                  !endereco.deleted && (
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      key={endereco.id}
                      style={{ position: 'relative' }}
                    >
                      <IconButton
                        onClick={() => removeEndereco(endereco.id)}
                        style={{ position: 'absolute', top: 16, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Grid
                        container
                        spacing={2}
                        className={classes.estabelecimentoEndereco}
                      >
                        {!endereco.ativo && (
                          <Grid item xs={12}>
                            <Typography variant='h6' style={{ color: 'red' }}>
                              Endereço excluído
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <TextField
                            id='endereco'
                            label='Endereço'
                            value={endereco.endereco || ''}
                            onChange={handleChange(
                              'endereco',
                              'enderecos',
                              endereco.id
                            )}
                            margin='normal'
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id='bairro'
                            label='Bairro'
                            value={endereco.bairro || ''}
                            onChange={handleChange(
                              'bairro',
                              'enderecos',
                              endereco.id
                            )}
                            margin='normal'
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id='cidade'
                            label='Cidade'
                            value={endereco.cidade || ''}
                            onChange={handleChange(
                              'cidade',
                              'enderecos',
                              endereco.id
                            )}
                            margin='normal'
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id='telefone'
                            label='Telefone'
                            value={endereco.telefone || ''}
                            onChange={handleChange(
                              'telefone',
                              'enderecos',
                              endereco.id
                            )}
                            margin='normal'
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
              )}
              <Grid item xs={12}>
                <Button
                  variant='outlined'
                  onClick={addEndereco}
                  style={{ width: '100%' }}
                >
                  Adicionar endereço
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='observacoes'
                  label='Observações'
                  value={estabelecimento.observacoes || ''}
                  onChange={handleChange('observacoes')}
                  margin='normal'
                  multiline
                  rows={5}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancelar
          </Button>

          <Button onClick={handleCreateOrUpdate} color='primary'>
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(DialogEstabelecimentosComponent)
