import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogActions, DialogTitle, List, ListItem, ListItemText, Tooltip } from '@material-ui/core'

import { linksInternos } from '../../constants/selectOptions'
import { styles } from '../../styles/js/dialog'

const DialogLinksInternosComponent = (props) => {
  const {
    classes,
    handleClose,
    dialogOpen,
    handleSelectedSuggestion,
  } = props

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="dialogTitle"
        scroll="body"
        PaperProps={{
          className: classes.dialog
        }}
      >
        <DialogTitle id="dialogTitle">
          Selecione um link interno
        </DialogTitle>
        
        <List>
          {linksInternos.map(suggestion => (
            <Tooltip title={suggestion.link} enterDelay={300} key={suggestion.id}>
              <ListItem button onClick={() => handleSelectedSuggestion(suggestion)} key={suggestion.id}>
                <ListItemText primary={suggestion.titulo} />
              </ListItem>
            </Tooltip>
          ))}
        </List>

        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#c62334' }}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withStyles(styles)(DialogLinksInternosComponent)