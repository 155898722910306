export const styles = theme => ({
  card: {
    position: 'relative',
    backgroundColor: '#fff',
    height: '100%',
    border: '1px solid #ddd',
    borderRadius: 0,
    boxShadow: 'none',
    cursor: 'pointer',
  },

  cardMedia: {
    height: 180,
    objectFit: 'cover',
    objectPosition: 'top',
  },

  cardContent: {
    position: 'relative',
    marginBottom: 35,
  },
  
  cardTag: {
    width: 65,
    color: 'white',
    padding: '2px 4px',
    textAlign: 'center',
    fontSize: '9pt',
    fontWeight: 500,
    borderRadius: 5,
    display: 'inline-block',
    marginBottom: 10,
    marginRight: 5,
  },

  cardExpiration: {
    color: '#555',
    fontWeight: 400,
    fontSize: '10pt',
  },

  cardActions: {
    position: 'absolute',
    bottom: 0,
    right: 5,
  },

  cardAction: {
    padding: 6,
  },

  comportamentoTitle: {
    margin: '16px 0px',
  },
})