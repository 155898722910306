import React, { useState, useEffect, useContext } from 'react'
import { SnackbarContext } from '../../ContextProvider'
import { initialDialogDeleteState } from '../../constants/initialConstants'
import DialogCapaDeleteComponent from '../../components/Capa/DialogCapaDeleteComponent'

import { useRequest } from '../../hooks'

const DialogCapaDelete = (props) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const [dialogCapaDeleteState, setDialogCapaDeleteState] = useState(initialDialogDeleteState)
  const [idsPracas, setIdsPracas] = useState([])

  const { 
    isOpen,
    setIsOpen,
    feedId,
    setFeedToDelete,
    fetchDestaques,
    fetchFixos,
    fetchBreaking,
    fetchConteudoAutomatico,
  } = props

  const [feed, statusRequestFeed, fetchFeed] = useRequest(
    [],
    {
      url: `feed/${feedId}`,
      method: 'get',
    },
    null
  )

  const [, , deleteFeed] = useRequest(
    {},
    {
      url: 'feed',
      method: 'delete',
      data: { 
        ...dialogCapaDeleteState, 
        ids_pracas: dialogCapaDeleteState.selected_ids_pracas
      }
    },
    null,
    {
      onComplete: response => {
        if (typeof response === 'string') {
          fetchDestaques()
          fetchFixos()
          fetchBreaking()
          fetchConteudoAutomatico()
          handleClose()
          showSnackbar('Conteúdo deletado com sucesso nas praças selecionadas!', 'success')
        } else {
          showSnackbar('Por favor, ' + response[0], 'error')
        }
      }
    }
  )

  useEffect(() => {
    if (feedId) {
      fetchFeed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedId])

  useEffect(() => {
    if (feed.id) {
      let ids_pracas = []
      
      feed.feeds_praca.map(el => ids_pracas.push(el.id_praca))

      setIdsPracas([ ...ids_pracas ])

      setDialogCapaDeleteState({
        ...dialogCapaDeleteState,
        id_feed: feed.id,
        ids_pracas: ids_pracas,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed])

  const handleClose = () => {
    setFeedToDelete('')
    setDialogCapaDeleteState({ ids_pracas: [], id_feed: null, selected_ids_pracas: [] })
    setIsOpen(false)
  }

  const handleDelete = () => {
    deleteFeed()
  }

  return (
    <DialogCapaDeleteComponent
      handleDelete={handleDelete}
      feed={feed}
      isOpen={isOpen}
      handleClose={handleClose}
      dialogCapaDeleteState={dialogCapaDeleteState}
      setDialogCapaDeleteState={setDialogCapaDeleteState}
      idsPracas={idsPracas}
      statusRequestFeed={statusRequestFeed}
    />
  )
}

export default DialogCapaDelete