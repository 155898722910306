export const styles = theme => ({
  card: {
    position: 'relative',
    height: '100%',
    border: '1px solid #ddd',
    borderRadius: 0,
    boxShadow: 'none',
    cursor: 'pointer',
  },

  cardContent: {
    position: 'relative',
  },

  cardImage: {
    width: '100%',
    height: 170,
    objectFit: 'cover',
    objectPosition: 'top',
  },

  cardSentBy: {
    marginTop: 12,
    color: '#666',
  },

  cardSentAt: {
    color: '#666',
  },
})