import React, { useState, useEffect } from 'react'
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import Notificacoes from '../../containers/Notificacoes/Notificacoes'
import DatePickerComponent from '../DatePickerComponent'
import DialogLookup from '../Dialogs/DialogLookup'
import { pracas, categorias } from '../../constants/selectOptions'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../../styles/js/dialog'
import DialogLinksInternos from '../../containers/DialogLinksInternos'

const DialogNotificacoesComponent = props => {
  const {
    classes,
    handleCreateOrUpdate,
    handleClose,
    isOpen,
    notificacao,
    setNotificacao,
    checkAllComportamentos,
    setCheckAllComportamentos,
    statusRequestNotificacao,
    interesses,
    populateLookupField,
  } = props

  const [dialogLinksInternosOpen, setDialogLinksInternosOpen] = useState(false)
  const [isLookupNotificacoesOpen, setIsLookupNotificacoesOpen] = useState(
    false
  )

  useEffect(() => {
    const handleCategoriaAndInteresses = (idCategoria, produto) => {
      if (parseInt(notificacao.id_categoria) === idCategoria) {
        const interessesCategoria = interesses
          .filter(el => el.produto === produto)
          .map(el => el.id)
        if (
          !notificacao.ids_interesses.every(el =>
            interessesCategoria.includes(el)
          )
        ) {
          setNotificacao({ ...notificacao, ids_interesses: [] })
        }
      }
    }

    handleCategoriaAndInteresses(1, 'G1')
    handleCategoriaAndInteresses(2, 'GE')
    handleCategoriaAndInteresses(3, 'GSHOW')
    handleCategoriaAndInteresses(4, 'RPC')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificacao.id_categoria])

  const getInteresses = () => {
    if (parseInt(notificacao.id_categoria) === 1) {
      return interesses.filter(el => el.produto === 'G1')
    }

    if (parseInt(notificacao.id_categoria) === 2) {
      return interesses.filter(el => el.produto === 'GE')
    }

    if (parseInt(notificacao.id_categoria) === 3) {
      return interesses.filter(el => el.produto === 'GSHOW')
    }

    if (parseInt(notificacao.id_categoria) === 4) {
      return interesses.filter(el => el.produto === 'RPC')
    }
  }

  const handleCheckboxChange = (id, nome) => event => {
    if (nome === 'checkAll') {
      let pracasComportamentos = []

      for (let i = 1; i <= 9; i++) {
        pracasComportamentos.push({ id_praca: i, id_comportamento: 0 })
      }

      setNotificacao({
        ...notificacao,
        ids_pracas: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        pracas_comportamentos: pracasComportamentos,
      })
      setCheckAllComportamentos(true)
      return
    }

    if (nome === 'uncheckAll') {
      setNotificacao({
        ...notificacao,
        ids_pracas: [],
        pracas_comportamentos: [],
      })
      setCheckAllComportamentos(false)
      return
    }

    let checkboxes = notificacao[nome]

    if (event.target.checked) {
      checkboxes.push(id)
    } else {
      checkboxes.splice(checkboxes.indexOf(id), 1)
    }
    setNotificacao({ ...notificacao, [nome]: checkboxes })
  }

  const handleChipChange = (id, nome) => {
    let chips = notificacao[nome]

    if (notificacao.ids_interesses.includes(id)) {
      chips.splice(chips.indexOf(id), 1)
    } else {
      chips.push(id)
    }
    setNotificacao({ ...notificacao, [nome]: chips })
  }

  const handleChange = name => event => {
    setNotificacao({ ...notificacao, [name]: event.target.value })
  }

  const removeSelectedPush = () => {
    setNotificacao({ ...notificacao, id_push: null, nome_push: '' })
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={!!isOpen}
        onClose={handleClose}
        aria-labelledby='dialog-notificacoes'
      >
        <DialogTitle id='dialog-notificacoes'>
          {isOpen === 'create' ||
          isOpen === 'createFeedNotificacao' ||
          isOpen === 'copy'
            ? 'Nova Notificação'
            : `Atualizar Notificação ${notificacao.id ? `| ${notificacao.id}` : ''}`}
        </DialogTitle>

        {!statusRequestNotificacao.done && isOpen !== 'create' && (
          <div style={{ position: 'relative' }}>
            <LinearProgress
              style={{
                position: 'absolute',
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          </div>
        )}

        {(notificacao.id ||
          isOpen === 'create' ||
          isOpen === 'createFeedNotificacao') && (
          <DialogContent>
            <RadioGroup
              aria-label='Categorias'
              name='categorias'
              value={`${notificacao.id_categoria}`}
              onChange={handleChange('id_categoria')}
            >
              <Grid container>
                {categorias.map(categoria => (
                  <Grid item xs={12} sm={6} md={3} key={categoria.id}>
                    <FormControlLabel
                      value={`${categoria.id}`}
                      control={<Radio />}
                      label={categoria.nome}
                      key={categoria.id}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>

            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12}>
                <TextField
                  id='titulo'
                  label='Título/Chapéu'
                  value={notificacao.titulo}
                  onChange={handleChange('titulo')}
                  margin='normal'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='mensagem'
                  label='Mensagem'
                  value={notificacao.mensagem}
                  onChange={handleChange('mensagem')}
                  margin='normal'
                  multiline
                  rowsMax='4'
                  helperText={
                    notificacao.mensagem.length > 0
                      ? notificacao.mensagem.length
                      : ''
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='url'
                  label='URL'
                  value={notificacao.url}
                  onChange={handleChange('url')}
                  margin='normal'
                  disabled={isOpen === 'createFeedNotificacao'}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton
                          onClick={() => setDialogLinksInternosOpen(true)}
                        >
                          <LinkIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <div>
              {!populateLookupField && (
                <Grid container>
                  {notificacao.id_push ? (
                    <Chip
                      label={notificacao.nome_push}
                      onDelete={removeSelectedPush}
                      // className={classes.chip}
                      color='primary'
                    />
                  ) : (
                    <Grid item xs={12}>
                      <Button
                        color='primary'
                        style={{ width: '100%' }}
                        onClick={() => setIsLookupNotificacoesOpen(true)}
                      >
                        Enviar para usuários que receberam outro push
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
              <br />
              <Typography variant='h6' className={classes.sectionTitle}>
                Praças
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          checkAllComportamentos ||
                          notificacao.ids_pracas.length === 9
                        }
                        onChange={handleCheckboxChange(
                          '',
                          checkAllComportamentos ||
                            notificacao.ids_pracas.length === 9
                            ? 'uncheckAll'
                            : 'checkAll'
                        )}
                        value={
                          checkAllComportamentos ||
                          notificacao.ids_pracas.length === 9
                        }
                        disabled={isOpen === 'changeComportamento'}
                      />
                    }
                    label={
                      checkAllComportamentos ||
                      notificacao.ids_pracas.length === 9
                        ? 'Desmarcar Todos'
                        : 'Marcar todos'
                    }
                  />
                </Grid>
                {pracas.map(praca => (
                  <Grid item xs={12} sm={6} md={4} key={praca.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificacao.ids_pracas.includes(praca.id)}
                          onChange={handleCheckboxChange(
                            praca.id,
                            'ids_pracas'
                          )}
                          value={praca.id}
                          disabled={!!notificacao.id_push}
                        />
                      }
                      label={praca.nome}
                    />
                  </Grid>
                ))}
              </Grid>

              <br />
              <Grid container>
                <Grid item xs={12}>
                  {getInteresses().map(interesse => (
                    <Chip
                      key={interesse.id}
                      label={interesse.nome}
                      onClick={() =>
                        notificacao.id_push
                          ? {}
                          : handleChipChange(interesse.id, 'ids_interesses')
                      }
                      className={classes.chip}
                      color={
                        notificacao.ids_interesses.includes(interesse.id)
                          ? 'primary'
                          : 'default'
                      }
                      style={{
                        cursor: notificacao.id_push ? 'default' : 'pointer',
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DatePickerComponent
                  onChange={value =>
                    setNotificacao({ ...notificacao, data_hora: value })
                  }
                  value={notificacao.data_hora}
                  id='dataHora'
                  label='Data de Envio do Push'
                  disablePast={false}
                  disableFuture={false}
                  withTime
                  withoutMargins
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={notificacao.importante}
                      onChange={event =>
                        setNotificacao({
                          ...notificacao,
                          importante: event.target.checked,
                        })
                      }
                      value={notificacao.importante}
                    />
                  }
                  label='Importante?'
                  style={{ marginTop: 16 }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancelar
          </Button>

          <Button onClick={handleCreateOrUpdate} color='primary'>
            {isOpen === 'create' ||
            isOpen === 'createFeedNotificacao' ||
            isOpen === 'copy'
              ? 'Criar'
              : 'Atualizar'}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogLinksInternos
        dialogOpen={dialogLinksInternosOpen}
        setDialogOpen={setDialogLinksInternosOpen}
        getSuggestion={suggestion =>
          setNotificacao({ ...notificacao, url: suggestion.link })
        }
      />

      <DialogLookup
        Component={Notificacoes}
        title={'Notificações'}
        isOpen={isLookupNotificacoesOpen}
        setIsOpen={setIsLookupNotificacoesOpen}
        setLookupField={row => {
          if (notificacao.id !== row.id) {
            setNotificacao({
              ...notificacao,
              id_push: row.id,
              nome_push: row.titulo,
            })
          }
        }}
      />
    </>
  )
}

export default withStyles(styles)(DialogNotificacoesComponent)
