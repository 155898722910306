import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Typography } from '@material-ui/core'

const DialogNotificacoesDeleteComponent = (props) => {
  const { 
    handleDelete,
    notificacao,
    isOpen,
    handleClose,
    dialogNotificacoesDeleteState,
    statusRequestNotificacao,
  } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-notificacoes"
    >
      <DialogTitle id="dialog-notificacoes">Excluir Notificação</DialogTitle>

      {(isOpen !== 'create' && !statusRequestNotificacao.done) &&
        <div style={{ position: 'relative' }}>
          <LinearProgress 
            style={{ 
              position: 'absolute', bottom: 0, top: 0, left: 0, right: 0 
            }}
          />
        </div>
      }

      {dialogNotificacoesDeleteState.id_notificacao &&
        <DialogContent>
          <DialogContentText id="dialog-notificacoes-description">
            Essa operação é irreversível. Você tem certeza que deseja excluir este registro?
          </DialogContentText>
          <Typography paragraph>
            {notificacao.titulo}
          </Typography>
        </DialogContent>
      }

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>

        <Button onClick={handleDelete} color="primary">
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogNotificacoesDeleteComponent