import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}))

/**
 * Material-UI CircularProgress with centralized position
 * @param {*} param0
 */
const CircularLoading = ({ size }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      className={classes.root}
    >
      <CircularProgress size={size} />
    </Grid>
  )
}

export default CircularLoading
