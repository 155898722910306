import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import ApplicationRouter from "./routes/ApplicationRouter.jsx";

const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomColor: "#006497"
        }
      }
    }
  },
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#006497"
    },
    secondary: {
      main: "#a60025"
    }
  }
});

const ApplicationStyle = () => {
  return (
    <CssBaseline>
      <MuiThemeProvider theme={theme}>
        <ApplicationRouter />
      </MuiThemeProvider>
    </CssBaseline>
  );
};

export default ApplicationStyle;
