import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { isMobile } from 'react-device-detect'
import arrayMove from 'array-move'
import CardCapa from './CardCapa'

const DraggableCardsCapa = (props) => {
  const { 
    destaques,
    setDestaques,
    fixos,
    setFixos,
    setFeedToUpdate,
    setFeedToDelete,
    setFeedToChangeComportamento,
    setFeedNotificacao,
    setChangeComportamentoState,
  } = props

  const SortableItem = SortableElement(({ value, type, index }) => {
    return (
      <Grid item xs={12} sm={6} md={type === 'Fixos' ? 3 : 4}>
        <CardCapa 
          value={value}
          setFeedToUpdate={setFeedToUpdate}
          setFeedToDelete={setFeedToDelete}
          setFeedToChangeComportamento={setFeedToChangeComportamento}
          setFeedNotificacao={setFeedNotificacao}
          showImage
        />
      </Grid>
    )
  })
  
  const Sortable = SortableContainer(({ items, title }) => {
    return (
      <Grid container alignItems='stretch'>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ margin: '16px 0px' }} >
            {title}
          </Typography>
        </Grid>
        
        <Grid container spacing={2}>
          {items.map((value, index) => (
            <SortableItem 
              key={value.id} 
              index={index} 
              value={value}
              type={title}
            />
          ))}
        </Grid>
      </Grid>
    )
  })

  const shouldCancelStart = (event) => {
    if (event.target.tagName === 'BUTTON' || event.target.tagName === 'path' || event.target.tagName === 'svg') {
      return true
    }
    return false
  }

  return (
    <div>
      <Sortable
        axis='xy'
        items={destaques} 
        onSortEnd={({ oldIndex, newIndex }) => {
          setDestaques(arrayMove(destaques, oldIndex, newIndex))
          setChangeComportamentoState({ 
            feeds: arrayMove(destaques, oldIndex, newIndex), 
            id_comportamento_feed: 2 
          })
        }}
        shouldCancelStart={event => shouldCancelStart(event)}
        title='Destaques'
        pressDelay={isMobile ? 200 : 0}
        useWindowAsScrollContainer
      />

      <Sortable
        axis='xy'
        items={fixos} 
        onSortEnd={({ oldIndex, newIndex }) => {
          setFixos(arrayMove(fixos, oldIndex, newIndex))
          setChangeComportamentoState({ 
            feeds: arrayMove(fixos, oldIndex, newIndex), 
            id_comportamento_feed: 2 
          })
        }}
        shouldCancelStart={event => shouldCancelStart(event)}
        title='Fixos'
        pressDelay={isMobile ? 200 : 0}
        useWindowAsScrollContainer
      />
    </div>
  )
}

export default DraggableCardsCapa