import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { initialDialogNotificacoesState } from '../../constants/initialConstants'
import { SnackbarContext } from '../../ContextProvider'
import { useRequest } from '../../hooks'

import DialogNotificacoesComponent from '../../components/Notificacoes/DialogNotificacoesComponent'

const DialogNotificacoes = (props) => {
  const { populateLookupField } = props
  const { showSnackbar } = useContext(SnackbarContext)

  const [notificacaoState, setNotificacaoState] = useState(initialDialogNotificacoesState)
  const [checkAllComportamentos, setCheckAllComportamentos] = useState(false)

  const {
    isOpen,
    setIsOpen,
    notificacaoId,
    setNotificacaoToUpdate,
    notificacaoToCopy,
    setNotificacaoToCopy,
    feedId,
    setFeedNotificacao,
    fetchNotificacoes,
  } = props
  
  const [notificacao, statusRequestNotificacao, fetchNotificacao] = useRequest(
    [],
    {
      url: `notificacao/${notificacaoId}`,
      method: 'get',
    },
    null
  )

  const [feed, statusRequestFeed, fetchFeed] = useRequest(
    [],
    {
      url: `feed/${feedId}`,
      method: 'get',
    },
    null
  )
    
  const [, , create] = useRequest(
    {},
    {
      url: 'notificacao',
      method: 'post',
      data: notificacaoState
    },
    null,
    {
      onComplete: response => {
        if (response.id) {
          handleClose()
          if (fetchNotificacoes) {
            fetchNotificacoes()
          }
          showSnackbar('Notificação criada com sucesso!', 'success')
        } else {
          showSnackbar('Por favor, ' + response[0], 'error')
        }
      }
    }
  )
    
    const [, , update] = useRequest(
      {},
      {
        url: 'notificacao',
        method: 'put',
        data: notificacaoState
      },
      null,
      {
        onComplete: response => {
          if (response.id) {
          handleClose()
          fetchNotificacoes()
          showSnackbar('Notificação editada com sucesso!', 'success')
        } else {
          showSnackbar('Por favor, ' + response[0], 'error')
        }
      }
    }
  )

  const [interesses, , fetchInteresses] = useRequest(
    [],
    {
      url: `interesses`,
      method: 'get',
    },
    null
  )

  useEffect(() => {
    fetchInteresses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    if (notificacaoId) {
      fetchNotificacao()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificacaoId])

  useEffect(() => {
    if (feedId) {
      fetchFeed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedId])
    
  useEffect(() => {
    if (notificacao.id) {
      let ids_pracas = []
      let ids_interesses = []
      let id_push
      let nome_push
      notificacao.notificacao_filtros.forEach(filtro => {
        if (filtro.tipo_filtro === 'Praca') {
          ids_pracas.push(filtro.id_filtro)
        }
        
        if (filtro.tipo_filtro === 'Interesse') {
          ids_interesses.push(filtro.id_filtro)
        }
        
        if (filtro.tipo_filtro === 'Notificacao') {
          id_push = filtro.id_filtro
          nome_push = filtro.notificacao_base.titulo
        }
      })
        
      setNotificacaoState({ 
        ...notificacao,
        ids_pracas: ids_pracas,
        ids_interesses: ids_interesses,
        id_push: id_push,
        nome_push: nome_push,
        data_hora: isOpen === 'copy' ? moment(new Date()) : moment(notificacao.data_hora)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificacao])

  useEffect(() => {
    if (feed.id) {
      let ids_pracas = []

      feed.feeds_praca.forEach(feed_praca => {
        ids_pracas.push(feed_praca.id_praca)
      })

      setNotificacaoState({
        ...initialDialogNotificacoesState,
        feedId: feed.id,
        ids_pracas: ids_pracas,
        titulo: feed.titulo_alt ? feed.titulo_alt : feed.titulo,
        url: feed.url,
        id_categoria: feed.id_categoria,
        data_hora: moment(new Date()),
      })
    }
  }, [feed])

  const handleCreate = () => {
    create()
  }

  const handleUpdate = () => {
    update()
  }

  const handleClose = () => {
    if (setNotificacaoToUpdate) {
      setNotificacaoToUpdate({})
    }

    if (setFeedNotificacao) {
      setFeedNotificacao({})
    }

    if (setNotificacaoToCopy) {
      setNotificacaoToCopy({})
    }

    setCheckAllComportamentos(false)
    setNotificacaoState(initialDialogNotificacoesState)
    setIsOpen(false)
  }

  return (
    <DialogNotificacoesComponent 
      handleCreateOrUpdate={(notificacaoId && isOpen !== 'copy') ? handleUpdate : handleCreate}
      handleClose={handleClose}
      isOpen={isOpen}
      notificacao={notificacaoState}
      setNotificacao={setNotificacaoState}
      notificacaoToCopy={notificacaoToCopy}
      checkAllComportamentos={checkAllComportamentos}
      setCheckAllComportamentos={setCheckAllComportamentos}
      statusRequestNotificacao={feed.id ? statusRequestFeed : statusRequestNotificacao}
      interesses={interesses}
      populateLookupField={populateLookupField}
    />
  )
}

export default DialogNotificacoes