import axios from "axios"

export const {
    API_BASE_URL,
  } = require(`../constants/${process.env.REACT_APP_ENV}/api`)

class Axios {
    constructor() {
        const baseURL = API_BASE_URL;
            this.api = axios.create({
              baseURL,
              timeout: 30000,
            });
            const tokenUsuario = JSON.parse(window.localStorage.getItem('tokenUsuario'))

            if(!!tokenUsuario && tokenUsuario !== null){
              const {token} = tokenUsuario
              this.api.defaults.headers.Authorization = `Bearer ${token}`;
            }
    }
}

export default new Axios().api;