import React, { useState, useEffect, useContext } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, LinearProgress, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { useRequest } from '../../hooks'
import { SnackbarContext } from '../../ContextProvider'

import SimpleTable from '../../components/SimpleTable'
import DialogCategoriasComponent from '../../components/Categorias/DialogCategoriasComponent'
import DialogCategoriasDeleteComponent from '../../components/Categorias/DialogCategoriasDeleteComponent'
import { initialCategoriasColumns } from '../../constants/initialConstants'

const DialogCategorias = (props) => {
  const { showSnackbar } = useContext(SnackbarContext)
  const { 
    isOpen, 
    handleClose,
    handleLinkSelecionado,
  } = props

  const [pagination, setPagination] = useState({ currentPage: 0, pageSize: 10 })
  const [searchState, setSearchState] = useState('')
  const [categoria, setCategoria] = useState({ ids_pracas: [], descricao: '' })

  const [isCategoriasDialogOpen, setIsCategoriasDialogOpen] = useState(false)
  const [isDeleteCategoriaDialogOpen, setIsDeleteCategoriaDialogOpen] = useState(false)

  const [categorias, statusRequestCategorias, fetchCategorias] = useRequest(
    [],
    {
      url: 'categoria_envio/listar',
      method: 'get',
      params: searchState ? { params: { palavras_chave: [searchState] }, ...pagination, buscaSimples: true } : pagination,
    },
    null
  )
  
  const [, , create] = useRequest(
    {},
    {
      url: 'categoria_envio',
      method: 'post',
      data: categoria
    },
    null,
    {
      onComplete: response => {
        if (response.id) {
          fetchCategorias()
          showSnackbar('Categoria criada com sucesso!', 'success')
        } else {
          showSnackbar('Por favor, ' + response[0], 'error')
        }
      }
    }
  )

  const [, , update] = useRequest(
    {},
    {
      url: 'categoria_envio/atualizar/dados',
      method: 'put',
      data: categoria
    },
    null,
    {
      onComplete: response => {
        if (response.id) {
          fetchCategorias()
          showSnackbar('Categoria alterada com sucesso!', 'success')
        } else {
          showSnackbar('Por favor, ' + response[0], 'error')
        }
      }
    }
  )

  const [, , deleteCategoria] = useRequest(
    {},
    {
      url: 'categoria_envio/atualizar/status',
      method: 'put',
      data: { id: categoria.id },
    },
    null,
    {
      onComplete: () => {
        showSnackbar('Status da categoria alterado com sucesso!', 'success')
        fetchCategorias()
        setIsDeleteCategoriaDialogOpen()
      }
    }
  )

  useEffect(() => {
    if (!!isOpen) {
      fetchCategorias()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      fetchCategorias()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  useEffect(() => {
    if (categoria.editMode || categoria.createMode) {
      setIsCategoriasDialogOpen(true)
    }

    if (categoria.deleteMode) {
      setIsDeleteCategoriaDialogOpen(true)
    }
  }, [categoria])
  
  const handleOnClick = (row) => event => {
    if (event.target.tagName === 'BUTTON' || event.target.tagName === 'path' || event.target.tagName === 'svg') {
      return false
    } else {
      handleLinkSelecionado(row)
    }
  }

  const handleCheckboxChange = (id, nome) => event => {
    let checkboxes = categoria[nome]

    if (event.target.checked) {
      checkboxes.push(id)
    } else {
      checkboxes.splice(checkboxes.indexOf(id), 1)
    }
    setCategoria({ ...categoria, [nome]: checkboxes })
  }

  const handleSearch = () => {
    fetchCategorias()
  }

  const handleCreateRow = () => {
    setCategoria({ ids_pracas: [1, 2, 3, 4, 5, 6, 7, 8, 9], createMode: true })
  }

  const handleEditRow = (row) => {
    // let ids_pracas = []
    // row.praca_categoria_envios.map(praca => ids_pracas.push(praca.id_praca))

    setCategoria({ 
      ...row,
      ids_pracas: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      editMode: true 
    })
  }

  const handleDeleteRow = (row) => {
    setCategoria({ ...row, deleteMode: true })
  }

  const handleCategoriasDialogClose = () => {
    setCategoria('')
    setIsCategoriasDialogOpen(false)
  }

  const handleDeleteCategoriaDialogClose = () => {
    setCategoria('')
    setIsDeleteCategoriaDialogOpen(false)
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={!!isOpen}
        onClose={handleClose}
        aria-labelledby="dialog-categorias"
      >
        <DialogTitle id="dialog-categorias">
          Gerenciar Categorias de Envio
        </DialogTitle>

        {!statusRequestCategorias.done &&
          <div style={{ position: 'relative' }}>
            <LinearProgress 
              style={{ 
                position: 'absolute', bottom: 0, top: 0, left: 0, right: 0 
              }}
            />
          </div>
        }

        <DialogContent>
          <Grid container direction='row' justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs={12} md={6}>
              <TextField
                id="search"
                label="Buscar"
                value={searchState}
                onChange={event => setSearchState(event.target.value)}
                margin="normal"
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="start">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Button color='primary' onClick={handleCreateRow}>
                Nova Categoria de Envio
              </Button>
            </Grid>
          </Grid>

          <SimpleTable 
            rows={categorias.rows ? categorias.rows : []}
            count={categorias.count ? categorias.count : 0}
            pagination={pagination}
            setPagination={setPagination}
            columns={initialCategoriasColumns}
            handleOnClick={handleLinkSelecionado ? handleOnClick : false}
            updateRow={handleEditRow}
            deleteRow={handleDeleteRow}
            dense
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <DialogCategoriasComponent
        isOpen={isCategoriasDialogOpen}
        handleClose={handleCategoriasDialogClose}
        categoria={categoria}
        setCategoria={setCategoria}
        action={categoria.editMode ? update : create}
        title={categoria.editMode ? 'Editar Categoria de Envio' : 'Criar Categoria de Envio'}
        actionTitle={categoria.editMode ? 'Atualizar' : 'Criar'}
        handleCheckboxChange={handleCheckboxChange}
      />
      <DialogCategoriasDeleteComponent
        isOpen={isDeleteCategoriaDialogOpen}
        handleClose={handleDeleteCategoriaDialogClose}
        categoria={categoria}
        handleDelete={deleteCategoria}
        handleCheckboxChange={handleCheckboxChange}
      />
    </>
  )
}

export default DialogCategorias