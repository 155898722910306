import React, { useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'

import DrawerContent from '../components/DrawerContent.jsx'
import { ApplicationContext } from '../ContextProvider.jsx'
import { logout } from '../helpers/authenticator'

import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/navbar'
import * as usuarios from '../constants/usuarios'

function Navbar(props) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const { classes } = props
  const { user } = useContext(ApplicationContext)

  const path = props.match.path

  return (
    <div>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar} elevation={0}>
        <Toolbar variant='dense'>
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            onClick={() => setMobileOpen(!mobileOpen)}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <img
            className={classes.logoRPC}
            src='/rpc-logo-fundo-escuro.png'
            alt='Logo RPC'
          />

          <Typography
            variant='h6'
            color='inherit'
            noWrap
            className={classes.navLinkMobile}
          >
            {props.appBarTitle}
          </Typography>

          {user.tipo === usuarios.CONTEUDO && (
            <Link to='/' className={classes.link}>
              <Typography
                variant='h6'
                color='inherit'
                noWrap
                className={
                  path === '/' ? classes.navLinkSelected : classes.navLink
                }
              >
                Capa
              </Typography>
            </Link>
          )}
          {user.tipo === usuarios.CONTEUDO && (
            <Link to='/notificacoes' className={classes.link}>
              <Typography
                variant='h6'
                color='inherit'
                noWrap
                className={
                  path === '/notificacoes'
                    ? classes.navLinkSelected
                    : classes.navLink
                }
              >
                Notificações
              </Typography>
            </Link>
          )}

          <Link to='/fotos-e-videos' className={classes.link}>
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              className={
                path === '/fotos-e-videos'
                  ? classes.navLinkSelected
                  : classes.navLink
              }
            >
              Fotos e Vídeos
            </Typography>
          </Link>

          {user.tipo === usuarios.JORNALISTA && (
            <Link to='/estabelecimentos' className={classes.link}>
              <Typography
                variant='h6'
                color='inherit'
                noWrap
                className={
                  path === '/estabelecimentos'
                    ? classes.navLinkSelected
                    : classes.navLink
                }
              >
                Estabelecimentos
              </Typography>
            </Link>
          )}

          <div className={classes.profileIcon}>
            <IconButton
              aria-label='Conta do usuário'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={(event) => setAnchorEl(event.currentTarget)}
              color='inherit'
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => setAnchorEl(null)}>{user.nome}</MenuItem>
              <MenuItem onClick={() => logout()}>Sair</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation='css'>
          <Drawer
            container={props.container}
            variant='temporary'
            anchor={'left'}
            open={mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}

export default withStyles(styles)(withRouter(Navbar))
