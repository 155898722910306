import React from 'react'
import moment from 'moment'
import { Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Paper, IconButton } from '@material-ui/core/'
import CheckboxIcon from '@material-ui/icons/CheckBox'
import CheckboxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import EditIcon from '@material-ui/icons/Edit'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { TableHead } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles/js/simpleTable'

const SimpleTable = (props) => {
  const { 
    classes, 
    rows,
    count,
    pagination,
    setPagination,
    columns, 
    handleOnClick,
    updateRow,
    deleteRow,
    dense,
  } = props

  const handleChangePage = (event, newPage) => {
    setPagination({ currentPage: newPage, pageSize: 10 })
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} padding={dense ? 'checkbox' : 'default'}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.name}>
                  {column.name}
                </TableCell>
              ))}
              {updateRow && 
                <TableCell key='edit'>
                  
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow 
                hover={handleOnClick ? true : false}
                style={{ 
                  cursor: handleOnClick ? 'pointer' : '', 
                  backgroundColor: row.ativo ? '' : '#edabab' 
                }} 
                key={row.id}
                onClick={handleOnClick ? handleOnClick(row) : () => {}}>
                {columns.map(column => (
                  <TableCell component="th" scope="row" key={row.id + column.value}>
                    {column.type === 'date' && 
                      moment(row[column.value]).format('DD/MM/YYYY')
                    }
                    {column.type === 'string' && 
                      row[column.value]
                    }
                  </TableCell>
                ))}
                {(updateRow || deleteRow) && 
                  <TableCell component='th' scope='row'>
                  {updateRow &&
                    <IconButton onClick={() => updateRow(row)}>
                      <EditIcon />
                    </IconButton>
                  }
                  {deleteRow && 
                    <IconButton onClick={() => deleteRow(row)}>
                      {row.ativo ? <CheckboxIcon /> : <CheckboxOutlineBlankIcon />}
                    </IconButton>
                  }
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                colSpan={columns.length + 1}
                count={count}
                rowsPerPage={10}
                page={pagination.currentPage}
                onChangePage={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(SimpleTable)

const TablePaginationActions = (props) => {
  const { count, page, onChangePage } = props

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1)
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1)
  }

  return (
    <div style={{ flexShrink: 0, marginLeft: 20, color: '#666' }}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Página anterior">
       <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / 10) - 1}
        aria-label="Próxima página"
      >
       <KeyboardArrowRight />
      </IconButton>
    </div>
  )
}