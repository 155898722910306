import React, { useState, useEffect } from 'react'
import { ApplicationContext } from './ContextProvider'
import ApplicationStyle from './ApplicationStyle'
import SnackbarProvider from './SnackbarProvider'
import { redirectToAuthentication, isAuthenticated, getLocalStorageToken } from './helpers/authenticator'
import { authStatuses } from './constants/authentication'
import { CircularProgress } from '@material-ui/core'

const handleAuthentication = () => {
  const authenticated = isAuthenticated()

  if (authenticated === authStatuses.UNAUTHENTICATED) {
    redirectToAuthentication()
  }

  return authenticated
}

const ApplicationContainer = () => {
  const [authentication, setAuthentication] = useState('')
  const [user, setUser] = useState('')

  useEffect(() => {
    if (handleAuthentication() === authStatuses.AUTHENTICATED) {
      setAuthentication(authStatuses.AUTHENTICATED)
      setUser(JSON.parse(getLocalStorageToken()).user)
    }
  }, [])

  const applicationContextValue = { 
    user,
    setUser,
    authentication,
  }

  return (
    <ApplicationContext.Provider value={applicationContextValue}>
      <SnackbarProvider>
        {user ?
        <ApplicationStyle /> :
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
          <CircularProgress
            color='primary' 
            size={60} 
            disableShrink />
        </div>
        }
      </SnackbarProvider>
    </ApplicationContext.Provider>
  )
}

export default ApplicationContainer
